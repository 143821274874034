import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Api} from "../services/api-config";
import {translate} from "../translate/translate";
import {toast} from "react-toastify";
import jwtDecode from "jwt-decode";
import {URLS} from "../services/app-urls";
import CryptoJS from "crypto-js";


type AuthContextType = {
    authenticated: boolean;
    user: any;
    loading: boolean;
    login: (username: any, password: string) => Promise<void>;
    logout: () => void;
};

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children}: { children: React.ReactNode }) => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
    const derived_key = CryptoJS.enc.Base64.parse("LefjQ2pEXmiy/nNZvEJ43i8hJuaAnzbA1Cbn1hOuAgA=");
    const iv = CryptoJS.enc.Utf8.parse("1020304050607080");

    const setupTimeout = () => {
        timeoutIdRef.current = setTimeout(() => {
            logout();
            toast.warning(translate.t("session_expired"));
        }, 60 * 60 * 5000);
    };

    const startTimeout = () => {
        if (!localStorage.getItem("token")) {
            return;
        }
        resetTimeout();
        setupTimeout();
    };

    const resetTimeout = () => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
            timeoutIdRef.current = null;
        }
    };

    const getToken = (name: string): string | undefined => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(";").shift();
        return undefined;
    };

    useEffect(() => {
        let token: string | null = localStorage.getItem("token");
        if (!token) {
            token = getToken("token") || null;
        }
        if (token) {
            const decodedToken: any = jwtDecode(token);
            const loggedUser = decodedToken.user;

            setUser(loggedUser);
            startTimeout();
        }

        setLoading(false);

        const resetTimeoutOnEvent = () => {
            resetTimeout();
            startTimeout();
        };

        window.addEventListener("click", resetTimeoutOnEvent);
        window.addEventListener("keydown", resetTimeoutOnEvent);

        return () => {
            resetTimeout();
            window.removeEventListener("click", resetTimeoutOnEvent);
            window.removeEventListener("keydown", resetTimeoutOnEvent);
        };
    }, []);

    const login = async (username: string, password: string) => {
        try {
            const encryptedUsername = CryptoJS.AES.encrypt(username, derived_key, {
                iv: iv,
                mode: CryptoJS.mode.CBC
            }).toString();
            const encryptedPassword = CryptoJS.AES.encrypt(password, derived_key, {
                iv: iv,
                mode: CryptoJS.mode.CBC
            }).toString();

            const response = await Api.post(URLS.TOKEN, {username: encryptedUsername, password: encryptedPassword});
            const token = response.data.token;
            localStorage.setItem("token", token.access);

            const decodedToken: any = jwtDecode(token.access);
            const loggedUser = decodedToken.user;
            setUser(loggedUser);

            navigate("/home");

            setupTimeout();
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                toast.error(translate.t("invalid_credentials"));
            } else {
                toast.error(translate.t("login_failed"));
            }
        }
    };

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("module");
        localStorage.removeItem("lang");
        setUser(null);
        navigate("/login");

        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
            timeoutIdRef.current = null;
        }
    };

    return (
        <AuthContext.Provider
            value={{
                authenticated: !!user,
                user,
                loading,
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
