import React, {useEffect} from "react";
import Dialog from "@mui/material/Dialog";
import {translate} from "../../../../translate/translate";
import {
    Box,
    DialogTitle,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TextField,
    Typography
} from "@mui/material";
import {Close, Info} from "@mui/icons-material";
import {Field, Form, Formik} from "formik";
import {Demand} from "../../../../models/basic/demand";
import {URLS} from "../../../../services/app-urls";
import {BaseService} from "../../../../services/base-service";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";


const DialogInfoDemandComponent = (props: any) => {
    const {
        open,
        onClose,
        demand
    } = props;
    const service = new BaseService<Demand>(URLS.DEMAND);
    const [demandList, setDemandList] = React.useState<Demand[]>([]);
    const [productionOrderList, setProductionOrderList] = React.useState<string[]>([]);
    const displayedColumns = ["quantity", "balance"];
    const productionOrderDisplayedColumns = ["production_order"];

    useEffect(() => {
        getDemandList();
    }, [demand]);

    useEffect(() => {
        if (demandList.length > 0) {
            getProductionOrderList();
        }
        getDemandList();
    }, [demandList]);

    const getDemandList = () => {
        service.clearParameters();
        service.addParameter("product", demand.product.id);
        service.addParameter("version", demand.version.id);
        service.addParameter("reference_date", demand.reference_date);
        service.addParameter("expand", "product,version");
        service.getAll().then((response) => {
            const filteredList = response.data.filter(item => item.id !== demand.id);
            setDemandList(filteredList);
        });
    };

    const getProductionOrderList = () => {
        const productionOrderList = demand.production_order.split(",");
        setProductionOrderList(productionOrderList);
    };

    const handleClose = (result: boolean) => {
        onClose(result);
    };

    return (
        <div style={{ overflowY: "hidden"}}>
            <Dialog open={open} onClose={() => handleClose(false)} fullWidth={true} maxWidth={"sm"} >
                <DialogTitle sx={{backgroundColor: "primary.main", padding: "16px"}}>
                    <Grid container direction="row">
                        <Grid item xs>
                            <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px" display="flex"
                                alignItems="center">
                                <Info fontSize="large" sx={{paddingRight: "5px"}}/>
                                {translate.t("info_demand")}
                            </Typography>
                        </Grid>
                        <Grid sx={{cursor: "pointer", color: "#ffffff"}} alignItems="center" display="flex">
                            <Close onClick={() => handleClose(false)}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogTitle sx={{backgroundColor: "#ffffff"}}>
                    <Formik
                        initialValues={demand}
                        validateOnChange={true}
                        enableReinitialize
                        onSubmit={(values: any, {setSubmitting}) => {
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 500);
                        }}>
                        {({values}) => (
                            <Form autoComplete="off">
                                <Grid container paddingTop={2} direction="column" spacing={2}>
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs>
                                            <Field
                                                disabled={true}
                                                as={TextField}
                                                id="product"
                                                name="product.name"
                                                label={translate.t("product")}
                                                values={values}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {color: "black"}
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs>
                                            <Field
                                                disabled={true}
                                                as={TextField}
                                                id="model"
                                                name="product.model"
                                                label={translate.t("model")}
                                                values={values}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {color: "black"}
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Field
                                                disabled={true}
                                                as={TextField}
                                                id="legacy_code"
                                                name="product.legacy_code"
                                                label={translate.t("legacy_code")}
                                                values={values}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {color: "black"}
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs>
                                            <Field
                                                disabled={true}
                                                as={TextField}
                                                id="quantity"
                                                name="quantity"
                                                label={translate.t("quantity")}
                                                values={values}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {color: "black"}
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Field
                                                disabled={true}
                                                as={TextField}
                                                id="planned_quantity"
                                                name="planned_quantity"
                                                label={translate.t("planned_quantity")}
                                                values={values}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {color: "black"}
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Field
                                                disabled={true}
                                                as={TextField}
                                                id="balance"
                                                name="balance"
                                                label={translate.t("balance")}
                                                values={values}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {color: "black"}
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs>
                                            <Field
                                                disabled={true}
                                                as={TextField}
                                                id="realized"
                                                name="realized"
                                                label={translate.t("realized")}
                                                values={values}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {color: "black"}
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Field
                                                disabled={true}
                                                as={TextField}
                                                id="bone_pile"
                                                name="bone_pile"
                                                label={translate.t("bone_pile")}
                                                values={values}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {color: "black"}
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Field
                                                disabled={true}
                                                as={TextField}
                                                id="scrap"
                                                name="scrap"
                                                label={translate.t("scrap")}
                                                values={values}
                                                fullWidth
                                                InputLabelProps={{
                                                    shrink: true,
                                                    style: {color: "black"}
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>

                    <Grid display="flex" flexDirection="row" marginTop={3}>
                        <Box>
                            <Paper variant="outlined">
                                <Box sx={{backgroundColor: "#f1f0f0"}} padding={2}>
                                    <Typography variant="h6">{translate.t("history_month")}</Typography>
                                </Box>
                                <Box padding={1}>
                                    <Box style={{ maxHeight: "200px", overflowY: "auto" }}>
                                        <TableContainer elevation={0} variant="outlined" component={Paper}>
                                            <Table size={"small"}>
                                                <TableHead>
                                                    <TableRow sx={{flexDirection: "row-reverse", alignContent: "end"}}>
                                                        {displayedColumns.map((column: string) => (
                                                            <TableCell
                                                                sx={{paddingTop: "12px", paddingBottom: "12px"}}
                                                                key={column}>{translate.t(column)}</TableCell>
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {demandList && demandList.length > 0 ? (
                                                        demandList.map((row: any) => (
                                                            <TableRow
                                                                key={row.id}
                                                                sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                                                {displayedColumns.map((column: string) => (
                                                                    <TableCell key={column}>
                                                                        {row[column] ? row[column] : "-"}
                                                                    </TableCell>
                                                                ))}
                                                            </TableRow>
                                                        ))
                                                    ) : (
                                                        <TableRow>
                                                            <TableCell colSpan={displayedColumns.length} sx={{
                                                                textAlign: "center",
                                                                fontSize: "13px",
                                                                padding: "8px 0"}}>
                                                                {translate.t("empty_listing")}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid display="flex" flexDirection="row" marginTop={3}>
                        <Box>
                            <Box style={{ maxHeight: "200px", overflowY: "auto" }}>
                                <TableContainer elevation={0} variant="outlined" component={Paper}>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow sx={{flexDirection: "row-reverse", alignContent: "end"}}>
                                                {productionOrderDisplayedColumns.map((column: string) => (
                                                    <TableCell
                                                        sx={{paddingTop: "12px", paddingBottom: "12px"}}
                                                        key={column}>{translate.t(column)}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {productionOrderList && productionOrderList.length > 0 ? (
                                                productionOrderList.map((row: any, index: number) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                                        {productionOrderDisplayedColumns.map((column: string) => (
                                                            <TableCell key={column}>
                                                                {row ? row : "-"}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={productionOrderDisplayedColumns.length} sx={{
                                                        textAlign: "center",
                                                        fontSize: "13px",
                                                        padding: "8px 0"}}>
                                                        {translate.t("empty_listing")}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Grid>
                </DialogTitle>
            </Dialog>
        </div>
    );
};

export default DialogInfoDemandComponent;
