import React from "react";
import {BrowserRouter as Router, Navigate, Route, Routes as Switch} from "react-router-dom";
import HomeComponent from "../page/default/home/home.component";
import LoginComponent from "../page/default/login/login.component";
import {AuthProvider, useAuth} from "../contexts/auth-context";
import LayoutComponent from "../components/layout/layout.component";
import GroupComponent from "../page/account/group/group.component";
import UserComponent from "../page/account/user/user.component";
import UserItemComponent from "../page/account/user/user-item/user-item.component";
import GroupItemComponent from "../page/account/group/group-item/group-item.component";
import StationComponent from "../page/basic/station/station.component";
import StationItemComponent from "../page/basic/station/station-item/station-item.component";
import SegmentComponent from "../page/basic/segment/segment.component";
import SegmentItemComponent from "../page/basic/segment/segment-item/segment-item.component";
import ScheduledStopComponent from "../page/basic/scheduled-stop/scheduled-stop.component";
import ScheduledStopItemComponent from "../page/basic/scheduled-stop/scheduled-stop-item/scheduled-stop-item.component";
import MarkerComponent from "../page/basic/marker/marker.component";
import MarkerItemComponent from "../page/basic/marker/marker-item/marker-item.component";
import ReasonReplanningItemComponent from "../page/basic/reason-replanning/reason-replanning-item/reason-replanning-item.component";
import ReasonReplanningComponent from "../page/basic/reason-replanning/reason-replanning.component";
import CalendarEventTypeItemComponent from "../page/basic/calendar-event-type/calendar-event-type-item/calendar-event-type-item.component";
import CalendarEventTypeComponent from "../page/basic/calendar-event-type/calendar-event-type.component";
import ProductionLineComponent from "../page/basic/production-line/production-line.component";
import ProductionLineItemComponent from "../page/basic/production-line/production-line-item/production-line-item.component";
import ProductComponent from "../page/basic/product/product.component";
import ProductItemComponent from "../page/basic/product/product-item/product-item.component";
import ShiftComponent from "../page/basic/shift/shift.component";
import ShiftItemComponent from "../page/basic/shift/shift-item/shift-item.component";
import ProductionRampComponent from "../page/basic/production-ramp/production-ramp.component";
import ProductionRampItemComponent from "../page/basic/production-ramp/production-ramp-item/production-ramp-item.component";
import DemandComponent from "../page/basic/demand/demand.component";
import DemandItemComponent from "../page/basic/demand/demand-item/demand-item.component";
import SmtDefaultTimeComponent from "../page/basic/smt-default-time/smt-default-time.component";
import SmtDefaultTimeItemComponent from "../page/basic/smt-default-time/smt-default-time-item/smt-default-time-item.component";
import ManualDefaultTimeComponent from "../page/basic/manual-default-time/manual-default-time.component";
import ManualDefaultTimeItemComponent from "../page/basic/manual-default-time/manual-default-time-item/manual-default-time-item.component";
import ProductionPlanningComponent from "../page/basic/production-planning/production-planning.component";
import CalendarComponent from "../page/basic/calendar/calendar.component";
import ExpressionBuilderComponent from "../page/basic/expression-builder/expression-builder.component";
import ExpressionBuilderItemComponent from "../page/basic/expression-builder/expression-builder-item/expression-builder-item.component";
import Loading from "../components/loading/loading";
import ReasonReplanningReportComponent from "../page/reports/reason-replanning-report.component";
import DemandUsedHoursReportComponent from "../page/report/demand-used-hours/demand-used-hours-report.component";
import RealizedAndPlannedComponent from "../page/report/realized-and-planned/realized-and-planned.component";
import ImportMaterialTransitComponent from "../page/planning/import-material-transit/import-material-transit.component";
import ImportStockComponent from "../page/basic/import-stock/import-stock.component";
import UsageProductionLines from "../page/report/usage-production-lines/usage-production-lines";
import PlanningByDeliveryComponent from "../page/planning/planning-by-delivery/planning-by-delivery.component";
import PreferencesComponent from "../page/settings/preferences/preferences.component";
import PreferencesItemComponent from "../page/settings/preferences/preferences-item/preferences-item.component";
import DowntimeReasonComponent from "../page/basic/downtime-reason/downtime-reason.component";
import DowntimeReasonItemComponent from "../page/basic/downtime-reason/downtime-reason-item/downtime-reason-item.component";
import DowntimeItemComponent from "../page/basic/downtime/downtime-item/downtime-item.component";
import DowntimeComponent from "../page/basic/downtime/downtime.component";
import DowntimeRankingReportComponent from "../page/report/downtime-ranking-report/downtime-ranking-report.component";
import DowntimeSummaryByQuantityReportComponent from "../page/report/downtime-by-quantity-report/downtime-by-quantity-report";
import DowntimeSummaryByAvailableTimeReportComponent from "../page/report/downtime-by-available-time-report/downtime-by-available-time-report";
import ProducedQuantityComponent from "../page/report/produced-quantity/produced-quantity.component";
import OeeComponent from "../page/report/oee/oee.component";
import OeeMonthGoalComponent from "../page/basic/oee-month-goal/oee-month-goal.component";
import OeeMonthGoalItemComponent from "../page/basic/oee-month-goal/oee-month-goal-item/oee-month-goal-item.component";
import OeeYearGoalComponent from "../page/basic/oee-year-goal/oee-year-goal.component";
import OeeYearGoalItemComponent from "../page/basic/oee-year-goal/oee-year-goal-item/oee-year-goal-item.component";
import DashboardOeeComponent from "../page/dashboard/dashboard-oee/dashboard-oee.component";
import ImportLineScheduleComponent from "../page/dashboard/import-line-schedule/import-line-schedule.component";
import DashboardLineScheduleComponent from "../page/dashboard/line-schedule/line-schedule.component";
import DashboardBufferChartComponent from "../page/dashboard/buffer-chart/buffer-chart.component";
import DashboardWipVolumeComponent from "../page/dashboard/wip-volume/wip-volume.component";
import ActiveLineCountComponent from "../page/basic/active-line-count/active-line-count.component";
import ActiveLineCountItemComponent from "../page/basic/active-line-count/active-line-count-item/active-line-count-item.component";
import ActivePeopleCountComponent from "../page/basic/active-people-count/active-people-count.component";
import ActivePeopleCountItemComponent from "../page/basic/active-people-count/active-people-count-item/active-people-count-item.component";
import DashboardUphComponent from "../page/dashboard/uph/uph.component";
import DashboardUpphComponent from "../page/dashboard/upph/upph.component";
import DashboardUpphProductionLineComponent from "../page/dashboard/upph-per-production-line/upph-per-production-line.component";
import DashboardPlanningComponent from "../page/dashboard-planning/dashboard-planning.component";


export const Routes = () => {
    const Private = ({children}: { children: React.ReactNode }): JSX.Element | null => {
        const {authenticated, loading} = useAuth();

        if (loading) {
            return <Loading open={loading}/>;
        }
        if (!authenticated) {
            return <Navigate to="/login"/>;
        }
        return <LayoutComponent>{children}</LayoutComponent>;
    };

    return (
        <Router>
            <AuthProvider>
                <Switch>
                    <Route path="/login" element={<LoginComponent/>}/>
                    <Route path="/" element={<Private><HomeComponent/></Private>}/>
                    <Route path="/home" element={<Private><HomeComponent/></Private>}/>
                    <Route path="/account/user" element={<Private><UserComponent/></Private>}></Route>
                    <Route path="/account/user/:action" element={<Private><UserItemComponent/></Private>}></Route>
                    <Route path="/account/group" element={<Private><GroupComponent/></Private>}></Route>
                    <Route path="/account/group/:action" element={<Private><GroupItemComponent/></Private>}></Route>
                    <Route path="/registration/station" element={<Private><StationComponent/></Private>}></Route>
                    <Route path="/registration/station/:action" element={<Private><StationItemComponent/></Private>}></Route>
                    <Route path="/registration/segment" element={<Private><SegmentComponent/></Private>}></Route>
                    <Route path="/registration/segment/:action" element={<Private><SegmentItemComponent/></Private>}></Route>
                    <Route path="/registration/segment" element={<Private><SegmentComponent/></Private>}></Route>
                    <Route path="/registration/segment/:action" element={<Private><SegmentItemComponent/></Private>}></Route>
                    <Route path="/registration/marker" element={<Private><MarkerComponent/></Private>}></Route>
                    <Route path="/registration/marker/:action" element={<Private><MarkerItemComponent/></Private>}></Route>
                    <Route path="/registration/reason_replanning" element={<Private><ReasonReplanningComponent/></Private>}></Route>
                    <Route path="/registration/reason_replanning/:action" element={<Private><ReasonReplanningItemComponent/></Private>}></Route>
                    <Route path="/registration/scheduled_stop" element={<Private><ScheduledStopComponent/></Private>}></Route>
                    <Route path="/registration/scheduled_stop/:action" element={<Private><ScheduledStopItemComponent/></Private>}></Route>
                    <Route path="/registration/product" element={<Private><ProductComponent/></Private>}></Route>
                    <Route path="/registration/product/:action" element={<Private><ProductItemComponent/></Private>}></Route>
                    <Route path="/registration/calendar_event_type" element={<Private><CalendarEventTypeComponent/></Private>}></Route>
                    <Route path="/registration/calendar_event_type/:action" element={<Private><CalendarEventTypeItemComponent/></Private>}></Route>
                    <Route path="/registration/production_line" element={<Private><ProductionLineComponent/></Private>}></Route>
                    <Route path="/registration/production_line/:action" element={<Private><ProductionLineItemComponent/></Private>}></Route>
                    <Route path="/registration/shift" element={<Private><ShiftComponent/></Private>}></Route>
                    <Route path="/registration/shift/:action" element={<Private><ShiftItemComponent/></Private>}></Route>
                    <Route path="/registration/production_ramp" element={<Private><ProductionRampComponent/></Private>}></Route>
                    <Route path="/registration/production_ramp/:action" element={<Private><ProductionRampItemComponent/></Private>}></Route>
                    <Route path="/planning/demand" element={<Private><DemandComponent/></Private>}></Route>
                    <Route path="/planning/demand/:action" element={<Private><DemandItemComponent/></Private>}></Route>
                    <Route path="/production_capacity/smt_default_time" element={<Private><SmtDefaultTimeComponent/></Private>}></Route>
                    <Route path="/production_capacity/smt_default_time/:action" element={<Private><SmtDefaultTimeItemComponent/></Private>}></Route>
                    <Route path="/production_capacity/manual_default_time/" element={<Private><ManualDefaultTimeComponent/></Private>}></Route>
                    <Route path="/production_capacity/manual_default_time/:action" element={<Private><ManualDefaultTimeItemComponent/></Private>}></Route>
                    <Route path="/planning/production_planning" element={<Private><ProductionPlanningComponent/></Private>}></Route>
                    <Route path="/registration/calendar" element={<Private><CalendarComponent/></Private>}></Route>
                    <Route path="/settings/expression_builder" element={<Private><ExpressionBuilderComponent/></Private>}></Route>
                    <Route path="/settings/expression_builder/:action" element={<Private><ExpressionBuilderItemComponent/></Private>}></Route>
                    <Route path="/reports/reason_replanning_report" element={<Private><ReasonReplanningReportComponent/></Private>}></Route>
                    <Route path="/reports/demand" element={<Private><DemandUsedHoursReportComponent/></Private>}></Route>
                    <Route path="/reports/realized_vs_planned" element={<Private><RealizedAndPlannedComponent/></Private>}></Route>
                    <Route path="/planning/import_material_transit" element={<Private><ImportMaterialTransitComponent/></Private>}></Route>
                    <Route path="/reports/production_line_usage" element={<Private><UsageProductionLines/></Private>}></Route>
                    <Route path="/planning/planning_by_delivery" element={<Private><PlanningByDeliveryComponent/></Private>}></Route>
                    <Route path="/settings/preferences" element={<Private><PreferencesComponent/></Private>}></Route>
                    <Route path="/settings/preferences/:action" element={<Private><PreferencesItemComponent/></Private>}></Route>
                    <Route path="/planning/import_stock" element={<Private><ImportStockComponent/></Private>}></Route>
                    <Route path="/registration/downtime_reason" element={<Private><DowntimeReasonComponent/></Private>}></Route>
                    <Route path="/registration/downtime_reason/:action" element={<Private><DowntimeReasonItemComponent/></Private>}></Route>
                    <Route path="/registration/downtime" element={<Private><DowntimeComponent/></Private>}></Route>
                    <Route path="/registration/downtime/:action" element={<Private><DowntimeItemComponent/></Private>}></Route>
                    <Route path="/reports/downtime_ranking" element={<Private><DowntimeRankingReportComponent/></Private>}></Route>
                    <Route path="/reports/downtime_summary_by_quantity_report" element={<Private><DowntimeSummaryByQuantityReportComponent/></Private>}></Route>
                    <Route path="/reports/downtime_summary_by_available_time_report" element={<Private><DowntimeSummaryByAvailableTimeReportComponent/></Private>}></Route>
                    <Route path="/reports/produced_quantity" element={<Private><ProducedQuantityComponent/></Private>}></Route>
                    <Route path="/reports/oee" element={<Private><OeeComponent/></Private>}></Route>
                    <Route path="/registration/oee_month_goal" element={<Private><OeeMonthGoalComponent/></Private>}></Route>
                    <Route path="/registration/oee_month_goal/:action" element={<Private><OeeMonthGoalItemComponent/></Private>}></Route>
                    <Route path="/registration/oee_year_goal" element={<Private><OeeYearGoalComponent/></Private>}></Route>
                    <Route path="/registration/oee_year_goal/:action" element={<Private><OeeYearGoalItemComponent/></Private>}></Route>
                    <Route path="/dashboard/dashboard_oee" element={<Private><DashboardOeeComponent/></Private>}></Route>
                    <Route path="/dashboard/line_schedule" element={<Private><DashboardLineScheduleComponent/></Private>}></Route>
                    <Route path="/dashboard/buffer_chart" element={<Private><DashboardBufferChartComponent/></Private>}></Route>
                    <Route path="/dashboard/wip_volume" element={<Private><DashboardWipVolumeComponent/></Private>}></Route>
                    <Route path="/dashboard/import_line_schedule" element={<Private><ImportLineScheduleComponent/></Private>}></Route>
                    <Route path="/registration/active_line_count" element={<Private><ActiveLineCountComponent/></Private>}></Route>
                    <Route path="/registration/active_line_count/:action" element={<Private><ActiveLineCountItemComponent/></Private>}></Route>
                    <Route path="/registration/active_people_count" element={<Private><ActivePeopleCountComponent/></Private>}></Route>
                    <Route path="/registration/active_people_count/:action" element={<Private><ActivePeopleCountItemComponent/></Private>}></Route>
                    <Route path="/dashboard/uph" element={<Private><DashboardUphComponent/></Private>}></Route>
                    <Route path="/dashboard/upph" element={<Private><DashboardUpphComponent/></Private>}></Route>
                    <Route path="/dashboard/upph_production_line" element={<Private><DashboardUpphProductionLineComponent/></Private>}></Route>
                    <Route path="/dashboard/planning" element={<DashboardPlanningComponent/>}/>
                    <Route path="/dashboard_planning" element={<DashboardPlanningComponent/>}/>
                </Switch>
            </AuthProvider>
        </Router>
    );
};
