import {
    Box,
    Checkbox,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import {translate} from "../../translate/translate";
import * as React from "react";
import {useEffect} from "react";
import TableMenuComponent from "../table-menu/table-menu.component";
import {useAuth} from "../../contexts/auth-context";
import {Value} from "./value-row-column.component";
import Divider from "@mui/material/Divider";
import {Choice} from "../../dto/choice";
import {ErrorComponent} from "../error/error.component";
import {DateTimeFormat} from "../date/date-timeFormat";

const TablePaginationComponent = (props: any) => {
    const {
        displayedColumnsShow,
        displayedColumns,
        displayedTranslate,
        handleSwitch,
        columnStyles,
        field,
        fieldList,
        service,
        dateColumns,
        showActions,
        handleDuplicate,
        handleRowSelect,
        selectedRows
    } = props;
    const {user} = useAuth();

    const [choices, setChoices] = React.useState<Choice[]>([]);
    const [choicesList, setChoicesList] = React.useState<any>({});

    const getChoices = () => {
        if (field) {
            service.getChoices(field).then((response: any) => {
                setChoices(response?.map((item: any) => item));
            }).catch((error: any) => {
                ErrorComponent(error);
            });
        }
    };

    const getChoicesList = () => {
        if (fieldList) {
            fieldList.forEach((field: string) => {
                service.getChoices(field).then((response: any) => {
                    setChoicesList((value: any) => ({
                        ...value,
                        [field]: response?.map((item: any) => item)
                    }));
                }).catch((error: any) => {
                    ErrorComponent(error);
                });
            });
        }
    };

    useEffect(() => {
        getChoices();
        getChoicesList();
    }, []);

    const translateTitleColumn = (column: string, index: number): string => {
        return displayedColumnsShow === undefined || displayedColumnsShow.length !== displayedColumns.length
            ? translate.t(column)
            : translate.t(displayedColumnsShow[index]);
    };

    const translateRowColumn = (column: string, index: number, row: any): string => {
        const value = String(Value(row, column));
        return (
            (value === "" || value === undefined || value === null)
                ? "-"
                : (
                    displayedTranslate !== undefined && displayedTranslate.length > 0 &&
                    displayedTranslate.find((choice: any) => choice == column) !== undefined
                )
                    ? translate.t(value)
                    : value
        );
    };

    const changeSwitch = (row: any): boolean => {
        return "email" in row && "username" in row && row.id === user.id;
    };

    const dateFormat = (column: string, row: any) => {
        const date_str = String(Value(row, column))
            .replace(/^(\d{4})-(\d{2})-(\d{2})$/,
                (match, year, month, day) => `${day}/${month}/${year}`);

        const time_str = String(Value(row, column))
            .match(/(\d{2}):(\d{2})/);

        if (time_str != null){
            return DateTimeFormat(Value(row, column));
        }

        return date_str;
    };

    const isSwitch = (column: string): boolean => {
        return column === "is_superuser" || column === "is_active" || column === "active" || column === "default" || column === "is_top_bottom";
    };

    const isDate = (column: string): boolean => {
        return dateColumns === undefined
            ? false
            : dateColumns.includes(column);
    };

    const renderCell = (column: string, index: number, row: any) => {
        if (column === field) {
            const choice = choices.find((choice: any) => choice.value === row[column]);
            return choice?.display_name;
        }
        if (fieldList && fieldList.includes(column)) {
            const choice = choicesList[column].find((choice: any) => choice.value === row[column]);
            return choice?.display_name;
        }
        return column === "isRowSelectable" ? (
            <Checkbox
                checked={selectedRows.some((selectedRow: any) => selectedRow.id === row.id)}
                onChange={() => handleRowSelect(row)}
            />
        ) : isSwitch(column) ? (
            <Switch
                color="secondary"
                checked={Value(row, column)}
                onChange={() =>
                    !changeSwitch(row) && handleSwitch(row, column)
                }
                disabled={row.id === user.id && row.username === user.username}
            />
        ) : (
            isDate(column)
                ? dateFormat(column, row)
                : (
                    column === "color" ?
                        <Box
                            width="20px"
                            height="20px"
                            borderRadius="50%"
                            bgcolor={row[column]}
                        ></Box>
                        : translateRowColumn(column, index, row)
                )
        );
    };

    const showPaginated = (from: number, to: number, count: number) => {
        return `${from} - ${to} ${translate.t("of")} ${count}`;
    };

    return (
        <Box sx={{width: "100%"}}>
            <Paper>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                {displayedColumns
                                    .filter((obj: string) => obj !== "action")
                                    .map((column: string, index: any) => (
                                        <TableCell
                                            key={column}
                                            sx={{
                                                lineHeighteight: "48px",
                                                padding: "0 14px",
                                                fontSize: "14px",
                                                fontWeight: "600",
                                                color: "white !important",
                                                backgroundColor: "#2962FF",
                                                paddingTop: "15px",
                                                paddingBottom: "15px",
                                                width:
                                                    columnStyles && columnStyles[index + 1]
                                                        ? columnStyles[index + 1]
                                                        : undefined,
                                            }}
                                        >
                                            {translateTitleColumn(column, index)}
                                        </TableCell>
                                    ))}
                                {displayedColumns.includes("action") && (
                                    <TableCell
                                        align="right"
                                        sx={{
                                            lineHeighteight: "48px",
                                            padding: "0 14px",
                                            fontSize: "14px",
                                            fontWeight: "600",
                                            color: "white !important",
                                            backgroundColor: "#2962FF",
                                            paddingTop: "15px",
                                            paddingBottom: "15px",
                                            width: "0%"
                                        }}>
                                        {translate.t("actions")}
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{cursor: "pointer"}}>
                            {props.state && props.state.length > 0 ? (
                                props.state.map((row: any) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                        onDoubleClick={() => {
                                            if (props.handleDoubleClick) {
                                                props.handleDoubleClick(row);
                                            }
                                        }}
                                    >
                                        {displayedColumns
                                            .filter((obj: string) => obj !== "action")
                                            .map((column: string, index: any) => (
                                                <TableCell key={column} sx={{wordBreak: "break-word", fontSize: "14px"}}>
                                                    {renderCell(column, index, row)}
                                                </TableCell>
                                            ))}
                                        {displayedColumns.includes("action") && (
                                            <TableMenuComponent
                                                row={row}
                                                handleDelete={props.handleDelete}
                                                url={props.url}
                                                handleOpenDialogHistory={props.handleOpenDialogHistory}
                                                editWithDialog={props.editWithDialog}
                                                showActions={showActions}
                                                handleDuplicate={handleDuplicate}
                                            />
                                        )}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={displayedColumns.length}
                                        sx={{
                                            textAlign: "left",
                                            fontSize: "14px",
                                            padding: "8px"
                                        }}
                                    >
                                        {translate.t("empty_listing")}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Divider/>
                <TablePagination
                    sx={{color: "#45485F", textAlign: "end", minHeight: "52px", maxHeight: "52px"}}
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    colSpan={12}
                    count={Number(props.count ?? 0)}
                    rowsPerPage={props.rowsPerPage}
                    page={props.page}
                    onPageChange={props.onPageChange}
                    onRowsPerPageChange={props.handleChangeRowsPerPage}
                    ActionsComponent={props.actionsComponent}
                    labelRowsPerPage={translate.t("rows_per_page")}
                    labelDisplayedRows={({from, to, count}) =>
                        showPaginated(from, to, count)
                    }
                />
            </Paper>
        </Box>
    );
};

export default TablePaginationComponent;
