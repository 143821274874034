import {BaseService} from "../../../../services/base-service";
import {Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ErrorComponent} from "../../../../components/error/error.component";
import {OeeMonthGoal} from "../../../../models/basic/oee-month-goal";
import Select from "@mui/material/Select";
import {useKeepFilters} from "../../../../utils/form-utils";


const OeeMonthGoalItemComponent = () => {
    const [object, setObject] = React.useState(new OeeMonthGoal());
    const service = new BaseService<OeeMonthGoal>(URLS.OEE_MONTH_GOAL);
    const params = useParams();
    const url = "/registration/oee_month_goal/";
    const [buttonValue, setButtonValue] = useState("");
    const navigate = useNavigate();
    const {searchParams} = useKeepFilters();
    const previousUrl = `${url}?${searchParams.toString()}`;

    const schema = Yup.object().shape({
        month: Yup.string().required(),
        year: Yup.string().required(),
        goal: Yup.string().required()
    });

    useEffect(() => {
        if (params["action"] != "create") {
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
            });
        }
    }, []);

    const handleSubmit = (values: OeeMonthGoal, {resetForm}: FormikHelpers<OeeMonthGoal>) => {
        setTimeout(() => {
            const oeeMonthGoal = new OeeMonthGoal();
            Object.assign(oeeMonthGoal, values);
            if (oeeMonthGoal.id) {
                service.patch(oeeMonthGoal, oeeMonthGoal.id).then(() => {
                    toast.success(translate.t("successfully_updated"));
                    if (buttonValue === "save") {
                        setObject(new OeeMonthGoal());
                        resetForm();
                    } else {
                        navigate(url);
                    }
                }).catch((error: any) => {
                    ErrorComponent(error);
                });
            } else {
                service.save(oeeMonthGoal)
                    .then(() => {
                        toast.success(translate.t("successfully_registered"));
                        if (buttonValue === "save") {
                            setObject(new OeeMonthGoal());
                            resetForm();
                        } else {
                            navigate(url);
                        }
                    })
                    .catch((error: any) => {
                        ErrorComponent(error);
                    });
            }
        }, 500);
    };

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={previousUrl}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("oee_month_goal")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={handleSubmit}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"row"}>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <InputLabel>{translate.t("month")}</InputLabel>
                                                <Field
                                                    as={Select}
                                                    name="month"
                                                    variant={"outlined"}
                                                    fullWidth
                                                    label={translate.t("month")}
                                                    autoFocus={true}
                                                    required={true}
                                                    values={values}>
                                                    <MenuItem value="1">{translate.t("january")}</MenuItem>
                                                    <MenuItem value="2">{translate.t("february")}</MenuItem>
                                                    <MenuItem value="3">{translate.t("march")}</MenuItem>
                                                    <MenuItem value="4">{translate.t("april")}</MenuItem>
                                                    <MenuItem value="5">{translate.t("may")}</MenuItem>
                                                    <MenuItem value="6">{translate.t("june")}</MenuItem>
                                                    <MenuItem value="7">{translate.t("july")}</MenuItem>
                                                    <MenuItem value="8">{translate.t("august")}</MenuItem>
                                                    <MenuItem value="9">{translate.t("september")}</MenuItem>
                                                    <MenuItem value="10">{translate.t("october")}</MenuItem>
                                                    <MenuItem value="11">{translate.t("november")}</MenuItem>
                                                    <MenuItem value="12">{translate.t("december")}</MenuItem>
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="year"
                                                type="number"
                                                label={translate.t("year")}
                                                variant={"outlined"}
                                                fullWidth
                                                required={true}
                                                values={values}
                                                error={touched.year && Boolean(errors.year)}
                                                InputProps={{
                                                    maxLength: 4,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.year && Boolean(errors.year) && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="goal"
                                                type="number"
                                                label={translate.t("goal") + " (%)"}
                                                variant={"outlined"}
                                                fullWidth
                                                required={true}
                                                values={values}
                                                error={touched.goal && Boolean(errors.goal)}
                                                InputProps={{
                                                    maxLength: 54,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.goal && Boolean(errors.goal) && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        <Grid item container direction={"row"}>
                                            <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        component={NavLink}
                                                        to={previousUrl}
                                                        variant="outlined">
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="success"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save_form"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save_form"
                                                        disabled={!isValid || isValidating || !values.month || !values.year || !values.goal}>
                                                        {translate.t("save_form")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save"
                                                        disabled={!isValid || isValidating || !values.month || !values.year || !values.goal}>
                                                        {translate.t("save_plus")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </div>
        </section>
    );
};

export default OeeMonthGoalItemComponent;
