import {
    Box,
    Button,
    Checkbox,
    Chip,
    Fab,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {translate} from "../../../translate/translate";
import * as React from "react";
import {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import {Version} from "../../../models/basic/version";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {toast} from "react-toastify";
import {ProductionLine} from "../../../models/basic/production-line";
import {
    AccountCircle,
    Add,
    ArrowBackIos,
    ArrowRight,
    AttachEmail,
    CalendarMonth, CalendarToday,
    CompareArrows,
    ContentCopy,
    Delete,
    DisplaySettings,
    Edit,
    Error,
    EventBusy,
    FileDownload,
    GridOff, Info, LocalHotelOutlined, ManageHistoryOutlined,
    MoreVertOutlined,
    PersonAdd,
    Search, SnoozeOutlined
} from "@mui/icons-material";
import DemandMenuComponent from "./demand-menu/demand-menu.component";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {Month} from "../../../dto/months";
import "./production-planning.component.css";
import DialogVersionComponent from "./dialog-version/dialog-version.component";
import DialogComponent from "../../../components/dialog/dialog.component";
import {PlanningCalendar, PlanningMonthKey} from "../../../dto/planning-calendar";
import {DateNowEnUS} from "../../../components/date/date-timeFormat";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import {Tooltip} from "react-tooltip";
import {Marker} from "../../../models/basic/marker";
import {DailyPlanning} from "../../../models/basic/daily-planning";
import Divider from "@mui/material/Divider";
import {useNavigate} from "react-router-dom";
import DialogMarkers from "./dialog-markers/dialog-markers.component";
import DialogPlanningDemandComponent from "./dialog-planning-demand/dialog-planning-demand.component";
import {ErrorComponent} from "../../../components/error/error.component";
import DialogInterruptDemandComponent from "./dialog-interrupt-demand/dialog-interrupt-demand.component";
import Loading from "../../../components/loading/loading";
import {Simulation} from "../../../models/planning/Simulation";
import DialogSimulationComponent from "./dialog-simulation/dialog-simulation.component";
import DialogCreateDemandComponent from "./dialog-create-demand/dialog-create-demand.component";
import {WebSocketService} from "../../../services/websocket-service";
import DialogSetFinalVersionComponent from "./dialog-set-final-version/dialog-set-final-version.component";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {jsPDF} from "jspdf";
import domtoimage from "dom-to-image-more";
import DialogShortageComponent from "./dialog-shortage/dialog-shortage.component";
import {Demand, DEMAND_STATUS} from "../../../models/basic/demand";
import DialogInfoDemandComponent from "./dialog-info-demand/dialog-info-demand.component";


const ProductionPlanningComponent = () => {
    const service = new BaseService<PlanningCalendar>(URLS.PLANNING);
    const serviceVersion = new BaseService<Version>(URLS.VERSION);
    const serviceMarker = new BaseService<Marker>(URLS.MARKER);
    const serviceDailyPlanning = new BaseService<DailyPlanning>(URLS.DAILY_PLANNING);
    const serviceProductionLine = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const serviceSimulation = new BaseService<Simulation>(URLS.SIMULATION);
    const serviceDemand = new BaseService<Demand>(URLS.DEMAND);
    const [selectedDemand, setSelectedDemand] = useState<any>();
    const [selectedShift, setSelectedShift] = useState<any>();
    const [selectedLine, setSelectedLine] = useState<any>();
    const [selectedDailyPlanning, setSelectedDailyPlanning] = useState<any>();
    const [sidenavOpened, setSidenavOpened] = useState(false);
    const [version, setVersion] = useState(new Version());
    const [searchDemand, setSearchDemand] = useState(false);
    const [versionList, setVersionList] = useState<Version[]>([]);
    const [markerList, setMarkerList] = useState<Marker[]>([]);
    const [dailyPlanningMarkers, setDailyPlanningMarkers] = useState<Marker[]>([]);
    const [start_date, setStartDate] = useState(dayjs());
    const [end_date, setEndDate] = useState(dayjs());
    const [openDialogMarkers, setOpenDialogMarkers] = useState(false);
    const [productionLine, setProductionLine] = useState<ProductionLine>();
    const [openVersionDialog, setOpenVersionDialog] = useState(false);
    const [openSetFinalVersionDialog, setOpenSetFinalVersionDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openShortageDialog, setOpenShortageDialog] = useState(false);
    const [openDeletePlanningDialog, setOpenDeletePlanningDialog] = useState(false);
    const [openEditPlanningDialog, setOpenEditPlanningDialog] = useState(false);
    const [openInterruptDemandDialog, setOpenInterruptDemandDialog] = useState(false);
    const [planning, setPlanning] = useState<PlanningCalendar>();
    const [monthKeys, setMonthKeys] = useState<PlanningMonthKey[]>([]);
    const navigate = useNavigate();
    const month_calendar = Month.loadObject();
    const [anchorEl, setAnchorEl] = useState(null);
    const [dailyAnchorEl, setDailyAnchorEl] = useState(null);
    const [markerAnchorEl, setMarkerAnchorEl] = useState(null);
    const [moreOptionsAnchorEl, setMoreOptionsAnchorEl] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [openCreateDemandDialog, setOpenCreateDemandDialog] = useState(false);
    const [openSimulationDialog, setOpenSimulationDialog] = useState(false);
    const [showProgressSimulation, setShowProgressSimulation] = useState(false);
    const [progressSimulation, setProgressSimulation] = useState<number>(0);
    const [openDemandInfoDialog, setOpenDemandInfoDialog] = useState(false);

    const handleVersionMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMoreOptionsMenuOpen = (event: any) => {
        setMoreOptionsAnchorEl(event.currentTarget);
    };

    const handleMoreOptionsMenuClose = () => {
        setMoreOptionsAnchorEl(null);
    };

    const handleVersionMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDailyMenuOpen = (event: any, dailyPlanning: any, shift: any, line: any) => {
        setSelectedShift(shift);
        setSelectedLine(line);
        setSelectedDailyPlanning(dailyPlanning);
        setDailyAnchorEl(event.currentTarget);
    };

    const handleOpenDialogMarker = (event: any, dailyPlanning: any) => {
        event.stopPropagation();
        setSelectedDailyPlanning(dailyPlanning);
        setOpenDialogMarkers(true);
    };

    const handleOpenDialogShortage = () => {
        setOpenShortageDialog(true);
    };

    const handleDailyMenuClose = () => {
        setDailyAnchorEl(null);
    };

    const handleMarkerMenuClick = (event: any) => {
        setMarkerAnchorEl(event.currentTarget);
    };

    const handleMarkerMenuClose = () => {
        setMarkerAnchorEl(null);
    };

    const handleOpenVersionDialog = () => {
        setOpenVersionDialog(true);
        handleVersionMenuClose();
    };

    const handleOpenSetFinalVersionDialog = () => {
        setOpenSetFinalVersionDialog(true);
        handleVersionMenuClose();
    };

    const handleCloseDeleteDialog = (result: boolean) => {
        setOpenDeleteDialog(false);
        if (result) {
            if (version.final_version) {
                toast.error("can_not_edit_planning_with_final_version");
                return;
            }
            const data = {version: version.url};
            service.clearParameters();
            service.postFromListRoute(data, "remove_version")
                .then(
                    () => {
                        getVersionList();
                        toast.success(translate.t("successfully_deleted"));
                    })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        }
    };

    const handleCloseDeletePlanningDialog = (result: boolean) => {
        setOpenDeletePlanningDialog(false);
        if (result) {
            service.delete(selectedDailyPlanning.worker_planning_id)
                .then(
                    () => {
                        reloadPlanningBoard();
                        setSearchDemand(!searchDemand);
                        toast.success(translate.t("successfully_deleted"));
                    })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        }
    };

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
        handleVersionMenuClose();
    };

    const handleOpenDeletePlanningDialog = () => {
        setOpenDeletePlanningDialog(true);
        setDailyAnchorEl(null);
    };

    const handleOpenDemandInfoDialog = () => {
        setOpenDemandInfoDialog(true);
        setDailyAnchorEl(null);
    };

    const handleOpenEditPlanningDialog = () => {
        setOpenEditPlanningDialog(true);
    };

    const handleInterruptDemandDialog = () => {
        setOpenInterruptDemandDialog(true);
    };

    const handleCloseSetFinalVersionDialog = (response?: any) => {
        setOpenSetFinalVersionDialog(false);
        if (response) {
            const data = {version: version.url, reason: response};
            service.clearParameters();
            service.postFromListRoute(data, "set_as_final_version")
                .then(
                    () => {
                        getVersionList();
                        sendFinalVersion();
                        toast.success(translate.t("successfully_updated"));
                    })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        }
    };

    const sendFinalVersion = (viewToast?: boolean, sendError = false) => {
        createFinalVersionFile().then((pdfBlob) => {
            if (!pdfBlob) {
                return;
            }

            const formData = new FormData();
            formData.append("file", pdfBlob, "final_version_planning.pdf");
            formData.append("version_name", version.name);
            formData.append("send_error", sendError.toString());

            service.postFromListRoute(formData, "send_final_version_planning")
                .then(() => {
                    if (viewToast) {
                        toast.success(translate.t("successfully_send_planning"));
                    }
                })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        });
    };

    const createFinalVersionFile = async (): Promise<Blob | null> => {
        const headerElement = document.getElementById("boxHeader");
        const linesShiftsElements = document.querySelectorAll("[id^=\"line-smt\"]");

        if (!headerElement || !linesShiftsElements.length) {
            return null;
        }
        const relevantHeaderElements = Array.from(headerElement.querySelectorAll(".month, .header-planning"));
        const originalBodyOverflow = document.body.style.overflow;
        document.body.style.overflow = "hidden";

        try {
            const pdf = new jsPDF("landscape");
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const title = translate.t("final_version_planning");

            pdf.setFontSize(20);
            const titleWidth = pdf.getTextWidth(title);
            const titleXPosition = (pageWidth - titleWidth) / 2;
            pdf.text(title, titleXPosition, 33);

            let yPosition = 50;

            const getValidDimensions = (img: HTMLImageElement) => {
                const aspectRatio = img.width / img.height;
                const width = pageWidth - 20;
                const height = width / aspectRatio;
                return { width, height };
            };

            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            if (!context) {
                return null;
            }

            const calculateColumnDimensions = (elements: Element[]) => {
                let columnWidth = 0;
                let columnHeight = 0;

                for (const element of elements) {
                    const elementWidth = element.scrollWidth;
                    const elementHeight = element.scrollHeight;

                    columnWidth = Math.max(columnWidth, elementWidth);
                    columnHeight += elementHeight;
                }

                return { columnWidth, columnHeight };
            };

            const drawColumnGroup = async (elements: Element[], xPosition: number, context: CanvasRenderingContext2D) => {
                let yPosition = 0;

                for (const element of elements) {
                    const imgData = await domtoimage.toPng(element as HTMLElement);
                    const img = new Image();
                    img.src = imgData;

                    await new Promise((resolve) => {
                        img.onload = () => {
                            context.drawImage(img, xPosition, yPosition, img.width, img.height);
                            yPosition += img.height;
                            resolve(null);
                        };
                    });
                }
            };

            const columnGroups: Element[][] = [];
            let currentGroup: Element[] = [];

            for (const element of relevantHeaderElements) {
                if (element.classList.contains("month") && currentGroup.length > 0) {
                    columnGroups.push(currentGroup);
                    currentGroup = [];
                }
                currentGroup.push(element);
            }
            if (currentGroup.length > 0) {
                columnGroups.push(currentGroup);
            }

            let totalWidth = 0;
            let maxHeight = 0;

            for (const group of columnGroups) {
                const { columnWidth, columnHeight } = calculateColumnDimensions(group);
                totalWidth += columnWidth;
                maxHeight = Math.max(maxHeight, columnHeight);
            }

            canvas.width = totalWidth;
            canvas.height = maxHeight;

            let currentXPosition = 0;

            for (const columnGroup of columnGroups) {
                const { columnWidth } = calculateColumnDimensions(columnGroup);
                await drawColumnGroup(columnGroup, currentXPosition, context);
                currentXPosition += columnWidth;
            }

            const combinedHeaderImage = canvas.toDataURL("image/png");
            const headerImage = new Image();
            headerImage.src = combinedHeaderImage;

            await new Promise((resolve) => {
                headerImage.onload = () => {
                    const { width, height } = getValidDimensions(headerImage);

                    if (yPosition + height > pageHeight) {
                        pdf.addPage();
                        yPosition = 10;
                    }

                    pdf.addImage(headerImage, "PNG", 10, yPosition, width, height);
                    yPosition += height;
                    resolve(null);
                };
            });

            if (linesShiftsElements.length > 0) {
                for (const imgData of Array.from(linesShiftsElements)) {
                    const img = new Image();
                    img.src = await domtoimage.toPng(imgData as HTMLElement);

                    await new Promise((resolve) => {
                        img.onload = () => {
                            const { width, height } = getValidDimensions(img);

                            if (yPosition + height > pageHeight) {
                                pdf.addPage();
                                yPosition = 10;
                            }

                            pdf.addImage(img, "PNG", 10, yPosition, width, height);
                            yPosition += height;
                            resolve(null);
                        };
                    });
                }
            }
            document.body.style.overflow = originalBodyOverflow;
            return pdf.output("blob");
        } catch (error: any) {
            ErrorComponent(error);
            return null;
        } finally {
            document.body.style.overflow = originalBodyOverflow;
        }
    };

    const handleCloseVersionDialog = (version?: Version) => {
        setOpenVersionDialog(false);
        if (version) {
            getVersionList(version);
        }
    };

    const handleScroll = (event: any) => {
        const newScrollPosition = event.target.scrollLeft;

        const boxHeader = document.getElementById("boxHeader");
        if (boxHeader) {
            boxHeader.scrollLeft = newScrollPosition;
        }
    };

    const schema = Yup.object().shape({
        version: Yup.string(),
        start_date: Yup.date(),
        end_date: Yup.date(),
        production_line: Yup.string()
    });

    const toggleSidenav = () => {
        setSidenavOpened(!sidenavOpened);
    };

    const handleVersionChange = (event: any) => {
        setVersion(event.target.value);
    };

    const handleStartDateChange = (event: any) => {
        setStartDate(event);
    };

    const handleEndDateChange = (event: any) => {
        setEndDate(event);
    };

    const handleProductionLineChange = (productionLine: ProductionLine) => {
        setProductionLine(productionLine);
    };

    const reloadPlanningBoard = () => {
        setLoading(true);
        service.clearParameters();
        service.addParameter("initial_date", dayjs(start_date).format("YYYY-MM-DD"));
        service.addParameter("final_date", dayjs(end_date).format("YYYY-MM-DD"));
        service.addParameter("version", version.id);
        if (productionLine) {
            service.addParameter("production_line", productionLine.id);
        }
        service.getFromListRoute("get_planning_calendar")
            .then((response) => {
                const keys = [];
                keys.push(
                    {
                        "year": "0",
                        "month": "0",
                        "yearMonth": "Lines"
                    }
                );
                Object.keys(response.data.header).forEach(yearMonth => {
                    const yearMonthSplit = yearMonth.split("-");
                    keys.push(
                        {
                            "year": yearMonthSplit[0],
                            "month": yearMonthSplit[1],
                            "yearMonth": yearMonth
                        }
                    );
                });
                setMonthKeys(keys);
                setPlanning(response.data);
            }).catch((error: any) => {
                ErrorComponent(error);
            }).finally(() => setLoading(false));
        setDailyAnchorEl(null);
        setMarkerAnchorEl(null);
    };

    const importProduction = () => {
        service.clearParameters();
        service.postFromListRoute({}, "import_production")
            .then((response: any) => {
                if (response["data"].length > 0) {
                    reloadPlanningBoard();
                    setSearchDemand(!searchDemand);
                    toast.success(translate.t("production_import_successful"));
                } else {
                    toast.warning(translate.t("nothing_to_import"));
                }
            }).catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const getVersionList = (selectedVersion?: Version) => {
        serviceVersion.clearParameters();
        serviceVersion.addParameter("active", true);
        serviceVersion.getAll().then(response => {
            setVersionList(response.data);
            let final_version;
            if (selectedVersion) {
                final_version = response.data.find(x => x.id === selectedVersion.id) as Version;
            } else {
                final_version = response.data.find(x => x.final_version) as Version;
            }
            final_version ? setVersion(final_version) : null;
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const getMarkerList = () => {
        serviceMarker.clearParameters();
        serviceMarker.addParameter("active", true);
        serviceMarker.getAll().then(response => {
            setMarkerList(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleDailyPlanningMarkers = () => {
        const dayMarkers = JSON.parse(JSON.stringify(markerList));
        if (selectedDailyPlanning.markers) {
            selectedDailyPlanning.markers.forEach((marker: any) => {
                const some = dayMarkers.find((m: any) => m.id === marker.marker);
                if (some) {
                    some.checked = true;
                }
            });
        }
        setDailyPlanningMarkers(dayMarkers);
    };

    const addMarker = (event: any, marker: Marker) => {
        if (selectedDailyPlanning.markers === null) {
            selectedDailyPlanning.markers = [];
        }
        const markers: any = [];
        selectedDailyPlanning.markers.forEach((marker: any) => markers.push(marker.url));
        const exists = markers.some((m: any) => m === marker.url);
        if (!exists) {
            markers.push(marker.url);
        } else if (exists) {
            const index = markers.indexOf(marker.url);
            markers.splice(index, 1);
        }
        serviceDailyPlanning.update({"marker": markers}, selectedDailyPlanning.daily_planning_id).then(
            () => toast.success(translate.t("successfully_updated"))
        );
        reloadPlanningBoard();
    };

    const getStringMonth = (index: string) => {
        return Object.values(month_calendar)[Number(index) - 1] as string;
    };

    const handleDialogClose = (value: any) => {
        setSelectedDailyPlanning(value);
        setOpenEditPlanningDialog(false);
        setOpenInterruptDemandDialog(false);
        reloadPlanningBoard();
        setSearchDemand(!searchDemand);
    };

    const handleOpenCreateDemandDialog = () => {
        setMoreOptionsAnchorEl(null);
        setOpenCreateDemandDialog(true);
        setDailyAnchorEl(null);
    };

    const handleOpenDialogSimulation = () => {
        setMoreOptionsAnchorEl(null);
        setOpenSimulationDialog(true);
        setDailyAnchorEl(null);
    };

    const removeSimulation = () => {
        setMoreOptionsAnchorEl(null);
        serviceSimulation.clearParameters();
        serviceSimulation.addParameter("version", version.id);
        serviceSimulation.getFromListRoute("remove_simulation")
            .then(() => {
                reloadPlanningBoard();
                setSearchDemand(!searchDemand);
                toast.success(translate.t("plannings_removed"));
            }).catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const handleSimulationClose = (value: any, simulation: any) => {
        setOpenSimulationDialog(false);
        if (value && simulation) {
            toast.success(translate.t("optimization_started"));
            setProgressSimulation(0);
            setShowProgressSimulation(true);
            const webSocketService = new WebSocketService("progress_simulation/?id=" + simulation.id);
            webSocketService.onMessage((response: any) => {
                const result: any = JSON.parse(response.data);
                if (result?.["version_id"] !== null) {
                    serviceVersion.getById(result?.["version_id"]).then((version_response: any) => {
                        toast.success(translate.t("optimization_finalized"));
                        getVersionList(version_response.data);
                        setVersion(version_response.data);
                        reloadPlanningBoard();
                        webSocketService.close();
                        setShowProgressSimulation(false);
                    });
                } else {
                    setProgressSimulation(result.progress);
                }
            });
        }
    };

    const handleCreateDemandClose = () => {
        setOpenCreateDemandDialog(false);
    };

    const handleShortageClose = () => {
        setOpenShortageDialog(false);
    };

    const handleCloseDemandInfoDialog = () => {
        setOpenDemandInfoDialog(false);
    };

    useEffect(() => {
        getMarkerList();
        setStartDate(dayjs(DateNowEnUS()));
        const end = new Date();
        end.setDate(end.getDate() + 14);
        setEndDate(dayjs(DateNowEnUS(end)));
        getVersionList();
    }, []);

    useEffect(() => {
        if (start_date && end_date && version.id) {
            reloadPlanningBoard();
        }
    }, [version]);

    useEffect(() => {
        if (selectedDailyPlanning?.demand_id) {
            serviceDemand.clearParameters();
            serviceDemand.addParameter("expand", "product,version");
            serviceDemand.getById(selectedDailyPlanning.demand_id).then((response) => {
                setSelectedDemand(response.data);
            });
        }
    }, [selectedDailyPlanning]);

    return (
        <Box>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("production_planning")}
                </Typography>
            </div>
            {showProgressSimulation && <div className="floating-title-progress" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("optimization_progress")}: {progressSimulation} %
                </Typography>
            </div>}
            <Loading open={loading}/>
            <Box className="main-content-planning">
                {sidenavOpened && (
                    <Paper elevation={0} square className={`menu-demand ${sidenavOpened ? "" : "closed"}`}>
                        {version.id && (<DemandMenuComponent version={version.id} searchDemand={searchDemand} setSearchDemand={setSearchDemand} onPlanning={reloadPlanningBoard}/>)}
                    </Paper>
                )}
                <Paper elevation={0} variant="outlined" sx={{
                    border: "0",
                    borderRadius: "0",
                    minHeight: "100%",
                    position: "relative",
                    marginLeft: sidenavOpened ? "250px" : 0,
                    width: sidenavOpened ? "calc(100% - 250px)" : "100%"
                }}>
                    {/* Filtro */}
                    <Box sx={{padding: "10px"}}>
                        <Formik
                            initialValues={{version: ""}}
                            validationSchema={schema}
                            validateOnChange={true}
                            enableReinitialize
                            onSubmit={(values: any, {setSubmitting}) => {
                                setTimeout(() => {
                                    setSubmitting(false);
                                }, 500);
                            }}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off" lang="pt-BR">
                                    <Grid alignItems="center" container spacing={1} direction={"row"}>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={toggleSidenav}
                                            >
                                                {sidenavOpened ? (
                                                    <>
                                                        <ArrowBackIos/>
                                                        {translate.t("demand")}
                                                    </>
                                                ) : (
                                                    <>
                                                        <ArrowForwardIosIcon/>
                                                        {translate.t("demand")}
                                                    </>
                                                )}
                                            </Button>
                                        </Grid>
                                        <Grid item xs lg={2}>
                                            <FormControl fullWidth>
                                                <InputLabel className={"formControl"} htmlFor="version">
                                                    {translate.t("version")}
                                                </InputLabel>
                                                <Field
                                                    as={TextField}
                                                    name="version"
                                                    variant={"outlined"}
                                                    fullWidth
                                                    component={Select}
                                                    autoFocus={false}
                                                    values={values}
                                                    value={version}
                                                    onChange={handleVersionChange}
                                                    error={touched.version && Boolean(errors.version)}
                                                    sx={{height: "52.71px"}}
                                                >
                                                    {
                                                        versionList.map((version: any) =>
                                                            <MenuItem key={version.id} value={version}>
                                                                {version.name}
                                                                {version.final_version && <Chip label="Versão final" className={"chip-version"} color="success" sx={{marginLeft: "8px"}}/>}
                                                                {version.simulation_version && <Chip label="Simulação" className={"chip-version"} color="secondary" sx={{marginLeft: "8px"}}/>}
                                                            </MenuItem>
                                                        )
                                                    }
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                color="secondary"
                                                onClick={handleVersionMenuOpen}>
                                                <MoreVertOutlined/>
                                            </Fab>
                                            {/*DIALOGS VERSAO*/}
                                            <DialogVersionComponent open={openVersionDialog} onClose={handleCloseVersionDialog} version={version}/>
                                            <DialogComponent
                                                open={openDeleteDialog} onClose={handleCloseDeleteDialog}
                                                labelButton={translate.t("delete")} title={translate.t("delete")}
                                                message={translate.t("confirm_delete")} description={version.name}
                                            />
                                            <DialogSetFinalVersionComponent open={openSetFinalVersionDialog} onClose={handleCloseSetFinalVersionDialog}/>
                                        </Grid>
                                        <Grid item xs lg={1.2}>
                                            <DatePicker
                                                maxDate={end_date}
                                                onChange={handleStartDateChange}
                                                slotProps={{
                                                    textField: {
                                                        name: "start_date",
                                                        label: translate.t("start_date"),
                                                        value: start_date,
                                                        fullWidth: true,
                                                        error: !start_date || (end_date && (start_date > end_date)),
                                                        InputLabelProps: {
                                                            shrink: true
                                                        },
                                                        InputProps: {
                                                            startAdornment: (
                                                                <InputAdornment position="end">
                                                                    {!start_date ? (
                                                                        <div className={"required-field"}>
                                                                            {translate.t("required_field")}
                                                                        </div>
                                                                    ) : null}
                                                                    {start_date && end_date && (start_date > end_date) ? (
                                                                        <div className={"error-field"}>
                                                                            {translate.t("must_be_less_equal_than_final_date")}
                                                                        </div>
                                                                    ) : null}
                                                                </InputAdornment>
                                                            ),
                                                        }
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs lg={1.2}>
                                            <DatePicker
                                                minDate={start_date}
                                                onChange={handleEndDateChange}
                                                slotProps={{
                                                    textField: {
                                                        name: "end_date",
                                                        required: true,
                                                        fullWidth: true,
                                                        label: translate.t("end_date"),
                                                        value: end_date,
                                                        error: !end_date || (start_date && (end_date < start_date)),
                                                        InputLabelProps: {
                                                            shrink: true
                                                        },
                                                        InputProps: {
                                                            startAdornment: (
                                                                <InputAdornment position="end">
                                                                    {!end_date ? (
                                                                        <div className={"required-field"}>
                                                                            {translate.t("required_field")}
                                                                        </div>
                                                                    ) : null}
                                                                    {end_date && start_date && (end_date < start_date) ? (
                                                                        <div className={"error-field"}>
                                                                            {translate.t("must_be_bigger_equal_than_start_date")}
                                                                        </div>
                                                                    ) : null}
                                                                </InputAdornment>
                                                            ),
                                                        }
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs lg={1.7}>
                                            <FormControl fullWidth>
                                                <AutocompletePaginated
                                                    display="name"
                                                    searchField="name"
                                                    label="production_line"
                                                    service={serviceProductionLine}
                                                    onSelectElement={handleProductionLineChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                color="secondary"
                                                disabled={!isValid || isValidating}
                                                onClick={reloadPlanningBoard}>
                                                <Search/>
                                            </Fab>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleMoreOptionsMenuOpen}>
                                                {translate.t("more_options")}
                                                <MoreVertOutlined/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>

                    {/* Mêses e Dias */}
                    <Box className="box-header" id="boxHeader" onScroll={handleScroll}>
                        {monthKeys.map((month, index) => {
                            return (
                                <Box width="auto" key={index}>
                                    <Box display="flex" justifyContent="start" alignItems="center" className="month">
                                        {month.month != "0" && (<Typography variant="body2">{translate.t(getStringMonth(month.month))}</Typography>)}
                                    </Box>
                                    <Box width="auto" display="flex" flexDirection="row" className="header-planning">
                                        {month.month === "0" ?
                                            (<>
                                                <Box className="phantom-box"></Box>
                                                <Box className="phantom-box"></Box>
                                            </>)
                                            :
                                            (<Box display="flex" flexDirection="row">
                                                {Object.keys(planning?.header).map((monthKey) => {
                                                    const monthData = planning?.header[monthKey];
                                                    return (
                                                        Object.keys(monthData).map((itemKey, index) => {
                                                            const item = monthData[itemKey];
                                                            return (
                                                                parseInt(month.month) == item.month &&
                                                                (<Box key={index} display="flex" flexDirection="column" className="header-day">
                                                                    <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-day">
                                                                        <Typography variant="body2">{translate.t(item?.["week_day"].toLowerCase())}</Typography>
                                                                    </Box>
                                                                    <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="header-number-day">
                                                                        <Typography variant="body2">{item.day}</Typography>
                                                                    </Box>
                                                                </Box>)
                                                            );
                                                        })
                                                    );
                                                })}
                                            </Box>)
                                        }
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    {/*Linhas e Turnos*/}
                    {planning?.body?.lines && (
                        <Box onScroll={handleScroll} className="body-planning">
                            {planning.body.lines.map((line: any, index: number) => {
                                return (
                                    <Box
                                        id="line-smt"
                                        key={index}
                                        width="fit-content"
                                        display="flex"
                                        position="relative"
                                        flexDirection="row">
                                        {line.type === "S" ?
                                            (<Box className="sticky-bg">
                                                <Box
                                                    className="smt-line-name"
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <DisplaySettings></DisplaySettings>
                                                    <Typography variant="body2">{line.name}</Typography>
                                                </Box>
                                            </Box>)
                                            :
                                            (<Box className="sticky-bg">
                                                <Box
                                                    className="manual-line-name"
                                                    display="flex"
                                                    flexDirection="column"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <AccountCircle></AccountCircle>
                                                    <Typography variant="body2">{line.name}</Typography>
                                                </Box>
                                            </Box>)
                                        }
                                        <Box position="relative" display="flex" flexDirection="column" className="shifts">
                                            {line.shifts.map((shift: any, index: number) => {
                                                return (
                                                    <Box key={index} position="relative" display="flex" flexDirection="row">
                                                        {line.type === "S" ?
                                                            (<Box className="sticky-bg" left="100px">
                                                                <Box className="smt-shift-name" display="flex" justifyContent="center" alignItems="center" sx={{height: "100%"}}>
                                                                    <Typography variant="body2">{shift.name}</Typography>
                                                                </Box>
                                                            </Box>)
                                                            :
                                                            (<Box className="sticky-bg" left="100px">
                                                                <Box className="manual-shift-name" display="flex" justifyContent="center" alignItems="center">
                                                                    <Typography variant="body2">{shift.name}</Typography>
                                                                </Box>
                                                            </Box>)
                                                        }
                                                        {/*Dados dos dias*/}
                                                        {shift.days.map((day: any, index: number) => {
                                                            return (
                                                                <Box
                                                                    id={`${line.id}${shift.id}${day}`}
                                                                    key={index} width="auto"
                                                                    position="relative" display="flex"
                                                                    flexDirection="row"
                                                                >
                                                                    <Box display="flex" flex="1" flexDirection="column">
                                                                        <Box display="flex" height="100%" position="relative" flexDirection="column" justifyContent="center">
                                                                            {!day?.["is_work_day"] && !day?.["event_description"] &&
                                                                                <Box
                                                                                    className="weekend"
                                                                                    sx={{height: "100%", margin: "1px"}}
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    justifyContent="center">
                                                                                </Box>
                                                                            }
                                                                            {!day?.["is_work_day"] && day?.["event_description"] &&
                                                                                <Box
                                                                                    sx={{height: (day.availability && day?.["is_work_day"]) ? "49%" : "100%", margin: "1px"}}
                                                                                    className="holiday"
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    justifyContent="center"
                                                                                    data-tooltip-content={day?.["event_description"]}
                                                                                    data-tooltip-id="tooltip"
                                                                                >
                                                                                    <Tooltip id="tooltip"/>
                                                                                    <Box
                                                                                        className="ic-holiday"></Box>
                                                                                </Box>
                                                                            }
                                                                            {day?.["partial_event"] &&
                                                                                <Box
                                                                                    sx={{height: (day.availability && day?.["is_work_day"]) ? "49%" : "100%", margin: "1px"}}
                                                                                    className="holiday"
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    justifyContent="center"
                                                                                    data-tooltip-content={day?.["partial_event"]}
                                                                                    data-tooltip-id="tooltip"
                                                                                >
                                                                                    <Tooltip id="tooltip"/>
                                                                                    <Box
                                                                                        className="ic-holiday"></Box>
                                                                                </Box>
                                                                            }
                                                                            {(day?.["daily_plannings"].length == 0 && day.availability && day?.["is_work_day"]) &&
                                                                                <Box
                                                                                    sx={{height: (day?.["partial_event"]) ? "49%" : "100%", margin: "1px"}}
                                                                                    className="day"
                                                                                    display="flex"
                                                                                    alignItems="center"
                                                                                    justifyContent="center">
                                                                                    <SnoozeOutlined className="person-add"></SnoozeOutlined>
                                                                                </Box>
                                                                            }
                                                                            {day?.["daily_plannings"].map((daily_planning: any, index: number) => {
                                                                                return (
                                                                                    <Box sx={{height: "98%", margin: "1px"}} key={index}>
                                                                                        <Box
                                                                                            key={index}
                                                                                            className="used-day"
                                                                                            display="flex"
                                                                                            flexDirection="row"
                                                                                            alignItems="center"
                                                                                            justifyContent="center"
                                                                                            sx={{
                                                                                                backgroundColor: daily_planning?.color,
                                                                                                height: (day.availability && day.daily_plannings.length == 1 && day?.is_work_day) ? "50%" : "100%"
                                                                                            }}
                                                                                            onClick={(event) => handleDailyMenuOpen(event, daily_planning, shift, line)}
                                                                                        >
                                                                                            {daily_planning?.markers && daily_planning?.markers.length > 0 &&
                                                                                                <Box
                                                                                                    onClick={(event) => handleOpenDialogMarker(event, daily_planning)}
                                                                                                    className="marker"
                                                                                                    data-tooltip-content=""
                                                                                                    data-tooltip-id="empty_tooltip"
                                                                                                >!
                                                                                                    <Tooltip
                                                                                                        id="empty_tooltip"/>
                                                                                                </Box>
                                                                                            }
                                                                                            <Box
                                                                                                display="flex"
                                                                                                flexDirection="column"
                                                                                                alignItems="center"
                                                                                                sx={{margin: "5px"}}
                                                                                            >
                                                                                                <Typography
                                                                                                    className="no-break-text"
                                                                                                    sx={{color: daily_planning.font_color}}>
                                                                                                    {
                                                                                                        daily_planning.is_npi == true ? "NPI" + " / " +
                                                                                                            daily_planning?.total_realized : daily_planning?.total_goal + " / " +
                                                                                                            daily_planning?.total_realized

                                                                                                    }
                                                                                                </Typography>
                                                                                                <Typography sx={{color: daily_planning.font_color, fontSize: "10px"}}>
                                                                                                    {daily_planning?.model}
                                                                                                </Typography>
                                                                                            </Box>

                                                                                        </Box>
                                                                                        {day.availability && day?.["daily_plannings"].length == 1 && day?.["is_work_day"] &&
                                                                                            <Box
                                                                                                sx={{height: "50%"}}
                                                                                                className="day"
                                                                                                display="flex"
                                                                                                alignItems="center"
                                                                                                justifyContent="center"
                                                                                            >
                                                                                                <SnoozeOutlined className="person-add"></SnoozeOutlined>
                                                                                            </Box>
                                                                                        }
                                                                                    </Box>
                                                                                );
                                                                            })}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            );
                                                        })}
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    )}
                </Paper>
            </Box>

            {/*MENU VERSAO*/}
            {version.final_version ?
                (<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleVersionMenuClose}>
                    <MenuItem onClick={handleOpenVersionDialog}>
                        <ListItemIcon>
                            <ContentCopy/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("duplicate_version")}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => sendFinalVersion(true, true)}>
                        <ListItemIcon>
                            <AttachEmail/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("send_final_version_planning")}
                        </ListItemText>
                    </MenuItem>
                </Menu>)
                :
                (<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleVersionMenuClose}>
                    <MenuItem onClick={handleOpenVersionDialog}>
                        <ListItemIcon>
                            <ContentCopy/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("duplicate_version")}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleOpenDeleteDialog}>
                        <ListItemIcon>
                            <Delete/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("delete_version")}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleOpenSetFinalVersionDialog}>
                        <ListItemIcon>
                            <CompareArrows/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("change_final_version")}
                        </ListItemText>
                    </MenuItem>
                </Menu>)
            }

            {/*MENU MAIS OPÇÕES*/}
            <Menu anchorEl={moreOptionsAnchorEl} open={Boolean(moreOptionsAnchorEl)} onClose={handleMoreOptionsMenuClose}>
                {version.simulation_version && <>
                    <MenuItem onClick={handleOpenCreateDemandDialog}>
                        <ListItemIcon>
                            <ContentCopy/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("create_demand")}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleOpenDialogSimulation}>
                        <ListItemIcon>
                            <Add/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("new_simulation")}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={removeSimulation}>
                        <ListItemIcon>
                            <EventBusy/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("delete_simulation")}
                        </ListItemText>
                    </MenuItem>
                </>
                }
                <MenuItem onClick={importProduction}>
                    <ListItemIcon>
                        <FileDownload/>
                    </ListItemIcon>
                    <ListItemText>
                        {translate.t("import_production")}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleOpenDialogShortage}>
                    <ListItemIcon>
                        <CalendarMonth/>
                    </ListItemIcon>
                    <ListItemText>
                        {translate.t("Shortage")}
                    </ListItemText>
                </MenuItem>
            </Menu>

            {/*MENU DAIlYPLANNING*/}
            <Menu id="menu_daily" anchorEl={dailyAnchorEl} open={Boolean(dailyAnchorEl)} onClose={handleDailyMenuClose}>
                <MenuList>
                    {selectedDailyPlanning && new Date(selectedDailyPlanning?.["daily_planning_date"]) > new Date() && (
                        <MenuItem onClick={() => handleOpenEditPlanningDialog()}>
                            <ListItemIcon>
                                <Edit/>
                            </ListItemIcon>
                            <ListItemText>
                                {translate.t("edit_planning")}
                            </ListItemText>
                        </MenuItem>
                    )}
                    <MenuItem onClick={handleOpenDeletePlanningDialog}>
                        <ListItemIcon>
                            <Delete/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("delete")}
                        </ListItemText>
                    </MenuItem>
                    {selectedDailyPlanning &&
                        selectedDemand?.status == DEMAND_STATUS.IN_PRODUCTION &&
                        new Date(selectedDailyPlanning?.["daily_planning_date"]).toDateString() >= new Date().toDateString() &&
                        (
                            <MenuItem onClick={handleInterruptDemandDialog}>
                                <ListItemIcon>
                                    <GridOff/>
                                </ListItemIcon>
                                <ListItemText>
                                    {translate.t("interrupt_demand")}
                                </ListItemText>
                            </MenuItem>
                        )
                    }
                    <MenuItem onClick={handleOpenDemandInfoDialog}>
                        <ListItemIcon>
                            <Info/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("info_demand")}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onMouseOver={(event) => {
                        handleDailyPlanningMarkers();
                        handleMarkerMenuClick(event);
                    }}>
                        <ListItemIcon>
                            <Error/>
                        </ListItemIcon>
                        <ListItemText>
                            <Box display="flex" justifyContent="space-between" sx={{padding: 0}}>
                                <Typography>
                                    {translate.t("markers")}
                                </Typography>
                                <ArrowRight/>
                            </Box>
                        </ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>

            {/*MENU MARCADORES*/}
            <Popover
                anchorEl={markerAnchorEl}
                open={Boolean(markerAnchorEl)}
                onClose={handleMarkerMenuClose}
                anchorOrigin={{vertical: "top", horizontal: "left"}}
                transformOrigin={{vertical: "top", horizontal: "left"}}>
                <MenuList sx={{width: "400px"}} onMouseLeave={handleMarkerMenuClose}>
                    {dailyPlanningMarkers.map((marker: any, index: number) => {
                        return (
                            <MenuItem key={index} onClick={(event) => addMarker(event, marker)}>
                                <ListItemIcon>
                                    <Checkbox edge="start" checked={marker.checked} tabIndex={-1} disableRipple/>
                                </ListItemIcon>
                                <ListItemText>
                                    <Box display="flex" flexDirection="row">
                                        <Typography className="marker-color" sx={{backgroundColor: marker.color}}></Typography>
                                        <Typography sx={{marginLeft: "5px"}}>{marker.description}</Typography>
                                    </Box>
                                </ListItemText>
                            </MenuItem>
                        );
                    })}
                    <Divider/>
                    <MenuItem onClick={() => navigate("/registration/marker/create")}>
                        <ListItemIcon>
                            <Add/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("new_marker")}
                        </ListItemText>
                    </MenuItem>
                </MenuList>
            </Popover>

            {/*DIALOGS MENU DAIlYPLANNING*/}
            <DialogComponent
                open={openDeletePlanningDialog} onClose={handleCloseDeletePlanningDialog}
                labelButton={translate.t("delete")}
                title={translate.t("delete")} message={translate.t("remove_planning_message")}
            />
            {openDialogMarkers && <DialogMarkers open={openDialogMarkers} onClose={() => setOpenDialogMarkers(false)} dailyPlanning={selectedDailyPlanning}></DialogMarkers>}
            {openEditPlanningDialog && selectedDailyPlanning && (
                <DialogPlanningDemandComponent
                    style="width: 1200px!important; max-width: 1200px!important;"
                    dailyPlanning={selectedDailyPlanning}
                    open={openEditPlanningDialog}
                    onClose={handleDialogClose}
                ></DialogPlanningDemandComponent>)}
            {openInterruptDemandDialog && (
                <DialogInterruptDemandComponent
                    style="width: 1200px!important; max-width: 1200px!important;"
                    dailyPlanning={selectedDailyPlanning}
                    open={openInterruptDemandDialog}
                    onClose={handleDialogClose}
                    version={version}
                ></DialogInterruptDemandComponent>)}
            {openSimulationDialog && (
                <DialogSimulationComponent
                    open={openSimulationDialog}
                    onClose={(value: boolean, simulation: any) => handleSimulationClose(value, simulation)}
                    version={version}>
                </DialogSimulationComponent>)}
            {openCreateDemandDialog && (
                <DialogCreateDemandComponent
                    open={openCreateDemandDialog}
                    onClose={handleCreateDemandClose}
                    version={version}>
                </DialogCreateDemandComponent>)}
            {openShortageDialog && (
                <DialogShortageComponent
                    open={openShortageDialog}
                    onClose={handleShortageClose}>
                </DialogShortageComponent>)}
            {openDemandInfoDialog && (
                <DialogInfoDemandComponent
                    style="width: 1200px!important; max-width: 1200px!important;"
                    open={openDemandInfoDialog}
                    onClose={handleCloseDemandInfoDialog}
                    demand={selectedDemand}>
                </DialogInfoDemandComponent>)}
        </Box>
    );
};

export default ProductionPlanningComponent;
