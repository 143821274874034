import {BaseService} from "../../../../services/base-service";
import {Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Downtime} from "../../../../models/basic/downtime";
import Select from "@mui/material/Select";
import {ErrorComponent} from "../../../../components/error/error.component";
import {ProductionLine} from "../../../../models/basic/production-line";
import {DowntimeReason} from "../../../../models/basic/downtime-reason";
import AutocompletePaginated from "../../../../components/autocomplete/autocompletePaginated";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {TimePicker} from "@mui/x-date-pickers";
import {useKeepFilters} from "../../../../utils/form-utils";

const DowntimeItemComponent = () => {
    const downtime = new Downtime();

    const [object, setObject] = React.useState<Downtime>(new Downtime());
    const downtimeService = new BaseService<Downtime>(URLS.DOWNTIME);
    const params = useParams();
    const url = "/registration/downtime/";

    const productionLineService = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const downtimeReasonService = new BaseService<DowntimeReason>(URLS.DOWNTIME_REASON);

    const [clearAutocomplete, setClearAutocomplete] = useState<boolean>(false);
    const [resetAutocomplete, setResetAutocomplete] = useState<boolean>(false);
    const [buttonValue, setButtonValue] = useState("");
    const navigate = useNavigate();
    const [productionLine, setProductionLine] = useState<ProductionLine | null>(null);
    const [downtimeReason, setDowntimeReason] = useState<DowntimeReason | null>(null);
    const [type, setType] = React.useState<string>("");

    const [startTime, setStartTime] = useState<any>(dayjs());
    const [startDate, setStartDate] = useState(dayjs());

    const [endTime, setEndTime] = useState<any>(dayjs().add(10, "minutes"));
    const [endDate, setEndDate] = useState(dayjs());

    const {searchParams} = useKeepFilters();
    const previousUrl = `${url}?${searchParams.toString()}`;

    useEffect(() => {
        if (params["action"] != "create") {
            downtimeService.clearParameters();
            downtimeService.addParameter("expand", ["production_line,reason"]);
            downtimeService
                .getById(parseInt(params["action"] as string))
                .then((response) => {
                    setType(response.data.type);
                    setProductionLine(response.data.production_line);
                    setDowntimeReason(response.data.reason);

                    setStartTime(response.data.start_date);
                    setStartDate(dayjs(response.data.start_date));

                    setEndTime(response.data.end_date);
                    setEndDate(dayjs(response.data.end_date));

                    setObject(response.data);
                })
                .catch((error: any) => {
                    ErrorComponent(error);
                });
        }
    }, [params]);

    const resetFields = (resetForm: any) => {
        setObject(new Downtime());
        setProductionLine(null);
        setDowntimeReason(null);
        setType("");
        setStartTime("");
        setStartDate(dayjs());
        setEndTime("");
        setEndDate(dayjs());
        setClearAutocomplete(true);
        setResetAutocomplete(true);
        resetForm();
    };

    const onSubmitDownTime = (values: Downtime, {resetForm}: FormikHelpers<Downtime>) => {
        setTimeout(() => {
            setClearAutocomplete(false);
            setResetAutocomplete(false);
            values.production_line = productionLine?.url || "";
            values.reason = downtimeReason?.url || "";
            values.type = type;
            values.start_date = startDate;
            values.start_time = startTime.format("HH:mm");
            values.end_date = endDate;
            values.end_time = endTime.format("HH:mm");
            Object.assign(downtime, values);

            if (downtime.id) {
                downtimeService
                    .patch(downtime, downtime.id)
                    .then(() => {
                        toast.success(translate.t("successfully_updated"));
                        if (buttonValue === "save") {
                            resetFields(resetForm);
                        } else {
                            navigate(url);
                        }
                    })
                    .catch((error: any) => {
                        ErrorComponent(error);
                    });
            } else {
                downtimeService
                    .save(downtime)
                    .then(() => {
                        toast.success(translate.t("successfully_registered"));
                        if (buttonValue === "save") {
                            resetFields(resetForm);
                        } else {
                            navigate(url);
                        }
                    })
                    .catch((error: any) => {
                        ErrorComponent(error);
                    });
            }
        }, 500);
    };

    const disabledSaveButtons = (): any => {
        return !type || !downtimeReason
            || !productionLine
            || !startDate || !startTime
            || !endDate || !endTime
            || endTime <= startTime
            || endDate < startDate;
    };

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={previousUrl}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("downtime")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={onSubmitDownTime}
                            validateOnChange={true}>
                            {({values, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"row"}>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <InputLabel required={true}>
                                                    {translate.t("type")}
                                                </InputLabel>
                                                <Field
                                                    as={Select}
                                                    name="type"
                                                    variant={"outlined"}
                                                    fullWidth
                                                    label={translate.t("type")}
                                                    error={touched.type && !type}
                                                    autoFocus={false}
                                                    required={true}
                                                    onChange={(event: any) => setType(event.target.value)}
                                                    value={type}
                                                    values={values}
                                                    inputprops={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {touched.type && !type && (
                                                                    <div className={"required-field"}>
                                                                        {translate.t("required_field")}
                                                                    </div>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                >
                                                    <MenuItem value="S">{translate.t("scheduled")}</MenuItem>
                                                    <MenuItem value="U">{translate.t("unscheduled")}</MenuItem>
                                                </Field>
                                                {(touched.type && !type) && (
                                                    <div className={"required-field"}>
                                                        {translate.t("required_field")}
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs sm md lg>
                                            <FormControl fullWidth>
                                                <AutocompletePaginated
                                                    required={true}
                                                    display="name"
                                                    searchField="name"
                                                    label="production_line"
                                                    service={productionLineService}
                                                    onSelectElement={(event: any) => setProductionLine(event)}
                                                    element={productionLine}
                                                    filters={[{"active": true}]}
                                                    clear={clearAutocomplete}
                                                    reset={resetAutocomplete}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs sm md lg>
                                            <FormControl fullWidth>
                                                <AutocompletePaginated
                                                    required={true}
                                                    display="description"
                                                    searchField="description"
                                                    label="downtime_reason"
                                                    service={downtimeReasonService}
                                                    onSelectElement={(event: any) => {
                                                        setDowntimeReason(event);
                                                    }}
                                                    element={downtimeReason}
                                                    filters={[{"active": true}]}
                                                    clear={clearAutocomplete}
                                                    reset={resetAutocomplete}
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs sm md lg>
                                            <TimePicker
                                                views={["hours", "minutes"]}
                                                onChange={(event: any) => setStartTime(event)}
                                                defaultValue={dayjs()}
                                                slotProps={{
                                                    textField: {
                                                        name: "start_time",
                                                        label: translate.t("start_time"),
                                                        required: true,
                                                        value: startTime,
                                                        error: !startTime,
                                                        fullWidth: true,
                                                        InputLabelProps: {
                                                            shrink: true
                                                        },
                                                        InputProps: {
                                                            startAdornment: (
                                                                <InputAdornment position="end">
                                                                    {!startTime && (
                                                                        <div className={"required-field"}>
                                                                            {translate.t("required_field")}
                                                                        </div>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }
                                                    },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs sm md lg>
                                            <DatePicker
                                                maxDate={endDate}
                                                onChange={(event: any) => setStartDate(event)}
                                                slotProps={{
                                                    textField: {
                                                        name: "start_date",
                                                        required: true,
                                                        label: translate.t("start_date"),
                                                        value: startDate,
                                                        error: !startDate || (startDate > endDate),
                                                        InputLabelProps: {
                                                            shrink: true
                                                        },
                                                        InputProps: {
                                                            startAdornment: (
                                                                <InputAdornment position="end">
                                                                    {!startDate ? (
                                                                        <div className={"required-field"}>
                                                                            {translate.t("required_field")}
                                                                        </div>
                                                                    ) : null}
                                                                    {startDate > endDate ? (
                                                                        <div className={"error-field"}>
                                                                            {translate.t("must_be_less_equal_than_final_date")}
                                                                        </div>
                                                                    ) : null}
                                                                </InputAdornment>
                                                            ),
                                                        }
                                                    },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs sm md lg>
                                            <TimePicker
                                                views={["hours", "minutes"]}
                                                defaultValue={dayjs().format("HH:mm")}
                                                onChange={(event: any) => setEndTime(event)}
                                                slotProps={{
                                                    textField: {
                                                        name: "end_time",
                                                        label: translate.t("end_time"),
                                                        required: true,
                                                        value: endTime,
                                                        fullWidth: true,
                                                        error: !endTime || (startTime && (endTime < startTime)),
                                                        InputLabelProps: {
                                                            shrink: true
                                                        },
                                                        InputProps: {
                                                            startAdornment: (
                                                                <InputAdornment position="end">
                                                                    {!endTime && (
                                                                        <div className={"required-field"}>
                                                                            {translate.t("required_field")}
                                                                        </div>
                                                                    )}
                                                                    {startTime && (endTime <= startTime) && (
                                                                        <div className={"error-field"}>
                                                                            {translate.t("must_be_bigger_than_start_time")}
                                                                        </div>
                                                                    )}
                                                                </InputAdornment>
                                                            ),
                                                        }
                                                    },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs sm md lg>
                                            <DatePicker
                                                onChange={(event: any) => setEndDate(event)}
                                                minDate={startDate}
                                                slotProps={{
                                                    textField: {
                                                        required: true,
                                                        name: "end_date",
                                                        label: translate.t("end_date"),
                                                        value: endDate,
                                                        error: !endDate || (endDate < startDate),
                                                        InputLabelProps: {
                                                            shrink: true
                                                        },
                                                        InputProps: {
                                                            startAdornment: (
                                                                <InputAdornment position="end">
                                                                    {!endDate ? (
                                                                        <div className={"required-field"}>
                                                                            {translate.t("required_field")}
                                                                        </div>
                                                                    ) : null}
                                                                    {endDate < startDate ? (
                                                                        <div className={"error-field"}>
                                                                            {translate.t("must_be_bigger_equal_than_start_date")}
                                                                        </div>
                                                                    ) : null}
                                                                </InputAdornment>
                                                            ),
                                                        }
                                                    },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item container direction={"row"}>
                                            <Grid item container direction={"row"}>
                                                <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                    <Grid item>
                                                        <Button
                                                            className="button-options"
                                                            color="secondary"
                                                            component={NavLink}
                                                            to={previousUrl}
                                                            variant="outlined">
                                                            {translate.t("cancel")}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            className="button-options"
                                                            color="success"
                                                            variant="contained"
                                                            type="submit"
                                                            value="save_form"
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                setButtonValue(event.currentTarget.value);
                                                            }}
                                                            name="save_form"
                                                            disabled={disabledSaveButtons()}>
                                                            {translate.t("save_form")}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            className="button-options"
                                                            color="secondary"
                                                            variant="contained"
                                                            type="submit"
                                                            value="save"
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                setButtonValue(event.currentTarget.value);
                                                            }}
                                                            name="save"
                                                            disabled={disabledSaveButtons()}>
                                                            {translate.t("save_plus")}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </div>
        </section>
    );
};

export default DowntimeItemComponent;
