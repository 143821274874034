const messages = {
    en: {
        translations: {
            code: "Code",
            description: "Description",
            search: "Search",
            active: "Active",
            created_at: "Created at",
            modified_at: "Updated at",
            actions: "Actions",
            cancel: "Cancel",
            save: "Save",
            sector: "Sector",
            production_line: "Production Line",
            automatic_phase: "Automatic Phase",
            phase: "Phase",
            machine: "Machine",
            process: "Process",
            new: "New",
            cost_center: "Cost center",
            tag_code: "Tag code",
            criticality: "Criticality",
            efficiency: "efficiency",
            last_maintenance_date: "Last maintenance date",
            frequency: "Frequency",
            utilization_index: "Utilization index",
            used_calendar: "Used calendar",
            conference_property: "Conference property",
            security_interval: "Security interval",
            unlimited_resource: "Unlimited resource",
            area: "Area",
            sequence: "Sequence",
            centesimal_working_hours: "Centesimal working hours",
            saturday_working_hours: "Saturday working hours",
            sunday_working_hours: "Sunday working hours",
            period_working_hours: "Period working hours",
            period_used_hours: "Period used hours",
            yield_percentage: "Yield percentage",
            warehouse_consumption: "Warehouse consumption",
            warehouse_description: "Warehouse description",
            shift: "Shift",
            initial: "Initial time",
            final: "Final time",
            start: "Start",
            product: "Product",
            unit_measurement: "Unit measurement",
            abbreviation: "Abbreviation",
            rows_per_page: "Rows per page",
            product_details: "Product details",
            item_code: "Item code",
            alternative_code: "Alternative code",
            specific_description: "Specific description",
            portuguese_description: "Portuguese description",
            english_description: "English_description",
            nickname: "Nickname",
            username: "Username",
            password: "Password",
            recoverPassword: "Recover password",
            confirmPassword: "Confirm password",
            email: "Email",
            superuser: "Super User",
            product_type: "Product type",
            raw_material: "Raw material",
            finished_product: "Finished product",
            intermediate_product: "Intermediate product",
            model: "Model",
            part_number: "Part number",
            part_number_client: "Part number client",
            manufacturer: "Manufacturer",
            expiration_date: "Expiration date",
            gross_weight: "Gross weight",
            revision: "Revision",
            last_revision: "Last revision",
            minimum_grade: "Minimum grade",
            division: "Division",
            inventory_frequency: "Inventory frequency",
            base_quantity_structure: "Base quantity structure",
            decimal_places_production_order: "Decimal places production order",
            normal_treatment_without_decimals: "Normal treatment without decimals",
            round: "Round",
            increments: "Incrementa",
            truncate_decimal_places: "Truncate decimal places",
            model_car: "Model car",
            direct_or_indirect_appropriation: "Direct or indirect appropriation",
            direct: "Direct",
            indirect: "Indirect",
            optional_default_group: "Optional default group",
            truncate_or_round: "Truncate or round",
            truncate: "Truncate",
            block: "Block",
            enter_mrp: "Enter MRP?",
            attend_need: "Attend need",
            notes: "Notes",
            unit_of_measurement: "Unit of measurement",
            unit_measurement_internal: "Unit measurement internal",
            unit_measurement_entry: "Unit measurement entry",
            second_unit_measurement: "Second unit measurement",
            conversion_factor: "Conversion factor",
            conversion_type: "Conversion type",
            multiplies: "Multiplies",
            divide: "Divide",
            cost_purchase: "Cost/Purchase",
            default_supplier: "Default supplier",
            store_default_supplier: "Store default supplier",
            minimum_quantity_order: "Minimum quantity oorder",
            reference_value_uss: "Reference value US$",
            sale_price: "Sale price",
            last_purchase_currency: "Last purchase currency",
            last_purchase_value: "Last purchase value",
            last_purchase: "Last purchase",
            default_cost_currency: "Default cost currency",
            default_cost: "Default cost",
            last_calculation_default_cost: "Last calculation default cost",
            fob_cost_currency: "Fob cost currency",
            fob_cost: "Fob cost",
            last_calculation_fob_cost: "Last calculation fob cost",
            delivery_time: "Delivery time",
            delivery_time_type: "Delivery time type",
            hours: "Hours",
            days: "Days",
            months: "Months",
            years: "Years",
            calculation_delivery_time_formula: "Calculation delivery time formula",
            tolerance: "Tolerance",
            stock: "Stock",
            stock_group: "Stock group",
            maximum_stock: "Maximum stock",
            security_stock: "Security stock",
            economic_lot: "Economic lot",
            minimum_lot: "Minimum lot",
            packing_quantity: "Packing quantity",
            security_stock_formula: "Security stock formula",
            default_warehouse: "Default warehouse",
            traceability: "Traceability",
            product_origin: "Product origin",
            national: "Product origin",
            foreign_direct_import: "Product origin",
            foreign_adq_domestic_market: "Product origin",
            foreign: "Product origin",
            product_traceability: "Product traceability",
            subbatch_traceability: "Subbatch traceability",
            does_not_use_traceability: "Does not use traceability",
            batch_traceability: "Batch traceability",
            default_production_map_operation: "Default production map operation",
            copy_quantity_bar_code: "Copy quantity bar code",
            bar_code: "Bar code",
            imported_product: "Imported product",
            purchased_out_state: "Purchased out state",
            family: "Family",
            ghost: "Ghost",
            controls_addressing: "Controls addressing",
            own_third_or_party: "Own third or party",
            own: "Own",
            third: "Third",
            product_base: "Product base",
            product_component: "Product component",
            loss_index: "Loss index",
            initial_validity: "Initial validity",
            final_validity: "Final validity",
            process_time: "Process time",
            quantity_type: "Quantity type",
            optional_group: "Optional group",
            item_optional_group: "Item optional group",
            initial_revision: "Initial revision",
            final_revision: "Final revision",
            product_level: "Product level",
            reverse_product_level: "Reverse product level",
            lot_potency: "Lot potency",
            warehouse: "Warehouse",
            quantity: "Quantity",
            alternative_use: "Alternative use",
            file_imported: "File imported",
            import_file: "Import file",
            import: "Import",
            user: "User",
            name: "Name",
            legacy_code: "Legacy code",
            group: "Group",
            menu: "Menu",
            visible: "Visible",
            permission: "Permission",
            successfully_updated: "Successfully updated",
            successfully_registered: "Successfully registered",
            successfully_deleted: "Successfully deleted",
            successfully_uploaded: "Successfully uploaded",
            required_field: "The field is required",
            exceeds_character_limit: "Exceeded character limit.",
            must_be_a_integer_number: "must be a integer number",
            must_be_a_number: "must be a number",
            unique_validation_failure: "unique validation failure",
            must_be_a_positive_number: "must be a positive number",
            cannot_insert_update_duplicate_key: "Cannot insert or update duplicate key",
            fixed: "Fixed",
            variable: "Variable",
            invalid_time_format: "Invalid time format",
            invalid_date_format: "Invalid date format",
            invalid_number_format: "Invalid number format",
            default_moment_locale: "en-in",
            token_error_redirecting: "Token error. Redirecting...",
            of: "of",
            basic_inputs: "Basic inputs",
            product_process: " Product - Process",
            invalid_file: "Invalid file",
            enter: "Enter",
            welcome_home: "Welcome to",
            invalid_credentials: "Unable to log in with the provided credentials.",
            logout: "Logout",
            profile: "Profile",
            session_expired: "Your session has expired.",
            session_information: "Log in to stay connected.",
            login_failed: "Unable to login, please check your connection.",
            confirm: "Confirm",
            edit: "Edit",
            delete: "Delete",
            save_form: "Save",
            superUser: "Administrator",
            confirm_delete: "Are you sure you want to delete this item?",
            password_not_match: "The passwords do not match.",
            product_line: "Product line",
            planning: "Planning",
            simulation: "Simulation",
            is_active: "Active",
            is_superuser: "Superuser",
            module: "Module",
            root_description: "Module",
            empty_listing: "No records found.",
            no_shift: "No shifts found.",
            new_password: "New password",
            confirm_new_password: "Confirm new password",
            undefined: "Undefined",
            security: "Security",
            status: "Status",
            inactive: "Inactive",
            codename: "Code",
            redefine_password: "Redefine password",
            grant_permission: "Grant all permissions.",
            deny_permission: "Deny all permissions.",
            codename_or_name: "Name / Code",
            error_user_selected: "Select a user to add the permission.",
            history: "History",
            history_update: "Updated",
            history_create: "Created",
            history_delete: "Deleted",
            is_staff: "Is staff",
            is_default: "Is default",
            true: "Yes",
            false: "No",
            modified_to: "modified to",
            general: "General",
            type_process: "Type process",
            hybrid: "Hybrid",
            available_production_line: "Available for production line:",
            used_production_line: "Used on the production line:",
            info_ordination: "Drag items to sort them.",
            segment: "Product Segment",
            station: "Station",
            programmed: "Programmed Shutdown",
            unscheduled_stop: "Unscheduled stop",
            confirm_type_process: "Confirm change.",
            confirm_type_process_text: "This production line has an associated station, do you really want to change the type of process? Associations will be lost.",
            programmed_shutdown: "Programmed Shutdown",
            duration: "Duration",
            type: "Type",
            commercial: "Part Number Commercial",
            name_or_commercial: "Commercial Part Number or Product",
            start_validity: "Validity start",
            end_validity: "Validity end",
            search_name: "Search name",
            search_programmed: "Search programmed",
            validity_start_date: "Validity start date",
            validity_end_date: "Validity end date",
            start_time: "Start time",
            end_time: "End time",
            error_required_field: "Make sure all required fields are filled in.",
            invalid_group: "A group with this name already exists.",
            the_record_already_exists: "The record already exists",
            replanning_reason: "Replanning Reason",
            reason: "Reason",
            cause: "Default Cause",
            yes: "Yes",
            no: "No",
            loading: "Loading",
            invalid_format: "Invalid format",
            order: "Order",
            search_shift: "Search shift",
            workday: "Workday",
            length: "Duration",
            error_programmed_selected: "Select a programmed shutdown to associate with the shift.",
            module_options: "What do you want to do?",
            production_capacity: "Production capacity",
            basic: "Basic",
            reports: "Reports",
            settings: "Settings",
            access_control: "Access control",
            invalid_email: "You must enter a valid email.",
            registrations: "Registrations",
            demand: "Demand",
            production_planning: "Production Planning",
            replanning_history: "Replanning history",
            smt_default_time: "Default SMT time",
            manual_default_time: "Manual default time",
            scheduled_stop: "Scheduled stops",
            reason_replanning: "Replanning reason",
            production_ramp: "Production ramp",
            calendar_event_type: "Calendar event type",
            calendar: "Calendar",
            marker: "Bookmark",
            reason_replanning_report: "Replanning reasons",
            production_line_usage: "Usage of production lines",
            realized_vs_planned: "Realized vs planned",
            preferences: "Preferences",
            expression_builder: "Expression Builder",
            headcount: "Number of people",
            stops: "Stops",
            line_configuration: "Line configuration",
            default: "Default",
            color: "Color",
            search_description: "Search description",
            useful_day: "Useful day",
            day_off: "Day off",
            pn_commercial: "PN Comercial",
            is_top_bottom: "Top-Bottom",
            component: "Component",
            sides: "Sides",
            add: "To add",
            production_ramp_settings: "Production ramp settings",
            npi: "NPI",
            side: "Side",
            version: "Version",
            production_order: "Production order",
            reference_date: "Reference date",
            font_color: "Font color",
            production_order_peripheral: "Production order peripherals",
            lines: "Lines",
            production_ramp_day: "Day ",
            validate_production_lines: "Production lines must contain the same settings",
            duplicate: "Duplicate",
            default_time_seconds: "Standard time",
            people_quantity: "People quantity",
            capacity: "Capacity",
            default_expression: "Default expression:",
            expression: "Expression",
            initial_date: "Initial date",
            final_date: "Final date",
            available: "Available",
            planned: "Planned",
            in_production: "In production",
            finished: "Finished",
            january: "January",
            february: "February",
            march: "March",
            april: "April",
            may: "May",
            june: "June",
            july: "July",
            august: "August",
            september: "September",
            october: "October",
            november: "November",
            december: "December",
            message_home: "P-Plan is a production planning system.",
            save_plus: "Save +",
            close: "Close",
            clear: "Clear",
            open: "Open",
            month: "Month",
            year: "Year",
            event: "Event",
            start_date: "Start date",
            end_date: "End date",
            validate_end_time: "This time must be longer",
            validate_event_create: "It is only allowed to create an event after the current date",
            available_time: "Available time",
            return_value: "Return value",
            default_time: "Default time",
            assemble_expression: "Assemble the expression",
            set_default_expression: "Set as default expression",
            AVAILABLE_TIME: "AVAILABLE TIME",
            DEFAULT_TIME: "DEFAULT TIME",
            PEOPLE_QUANTITY: "PEOPLE QUANTITY",
            CAPACITY: "VOLUME",
            volume: "Volume",
            day: "Day",
            line: "Line",
            duplicate_version: "Duplicate version",
            delete_version: "Delete version",
            change_final_version: "Change to final version",
            duplicate_version_successfully: "Duplicate version successfully",
            confirm_make_version_final: "Do you really want to make version ($description) final?",
            to_plan: "To plan",
            sunday: "Sunday",
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            new_marker: "New marker",
            peripheral: "Peripheral",
            interrupt_demand: "Interrupt demand",
            markers: "Markers",
            remove_planning_message: "Want to remove the planning?",
            can_not_edit_planning_with_final_version: "You cannot make a change to a production version. Change a duplicate version and then publish",
            production_line_top: "Production line TOP",
            production_line_bot: "Production line BOTTON",
            demand_total: "Demand total",
            shift_to_start: "Shift to start",
            line_setup_stop: "Line setup stop",
            different_line: "Phases A and B in different lines",
            shifts: "Shifts",
            production_line_backend: "Backend production line",
            associate_shift: "Associate shift",
            no_associated_shift: "No associated shift",
            value_day_one: "Value day 1",
            value_day_two: "Value day 2",
            value_day_three: "Value day 3",
            value_day_four: "Value day 4",
            value_day_five: "Value day 5",
            value_day_six: "Value day 6",
            value_day_seven: "Value day 7",
            production_line_required: "Select at least one production line",
            info_demand: "Demand information",
            edit_demand: "Demand edit",
            history_month: "Monthly history",
            balance: "Balance",
            planned_quantity: "Planned",
            move_balance: "Move balance",
            bone_pile: "Defect",
            scrap: "Waste",
            realized: "Realized",
            finalize_demand: "Finalize demand",
            info_finalize_demand: "Do you want to finalize the demand?",
            finalize: "Finalize",
            smt_configuration: "No SMT time setting",
            manual_configuration: "No Manual time setting",
            unknown_error: "Oops... there was an unexpected thing!",
            new_simulation: "New optimization",
            new_version: "New version",
            all_rights_reserved: "All rights reserved",
            developed_by: "Developed by",
            edit_planning: "Edit planning",
            smt_warnning: "SMT time",
            manual_warnning: "Manual time",
            valid_quantity: "Quantity cannot exceed the demand value",
            anticipate_planning: "Anticipate upcoming plans",
            replanning_amount: "Replanning amount",
            date: "Date",
            must_be_bigger_equal_than_start_date: "Must be bigger or equal than start date",
            must_be_less_equal_than_final_date: "Must be less or equal than final date",
            must_be_bigger_than_start_date: "Must be bigger than start date",
            must_be_less_than_final_date: "Must be less than final date",
            must_be_bigger_than_start_time: "Must be bigger than start time",
            must_be_less_than_final_time: "Must be less than final time",
            line_setup: "Line setup",
            no_records_found: "No records found",
            demand_used_hours: "Demand used hours",
            group_by: "Group by",
            consolidated: "Consolidated",
            detailed: "Detailed",
            import_material_transit: "Import material in transit",
            product_description: "Product description",
            delivery_date: "Delivery date",
            result: "Result",
            side_a: "Side A",
            side_b: "Side B",
            max_qtd: "Max quantity ",
            product_model: "Product model",
            title_event: "event",
            attention: "Attention",
            exists_planning: "There is already planning for this period, do you want to continue and replan?",
            add_delay: "Fill in to delay the start of the back-end production line.",
            delivery: "Delivery",
            planning_by_delivery: "Planning by delivery",
            no_planned: "No planned",
            input: "Input",
            create_demand: "Create demand",
            simulation_options: "Simulation options",
            max_value: "The field can't be greater than ",
            min_value: "The field can't be lesser than ",
            invalid_quantity: "Insufficient quantity to create demand",
            value: "Value",
            MAIL_HOST: "Mail host",
            MAIL_PORT: "Mail port",
            MAIL_SENDER: "Mail sender",
            MAIL_PASSWORD: "Mail password",
            MAIL_USE_TLS: "Mail use TLS",
            MAIL_USE_SSL: "Mail use SSL",
            DEMAND_DAYS_BEFORE_VALIDATE: "Days before validate demands",
            EMAILS_TO_ALERT: "E-mails to alert",
            PRODUCTION_PLAN_XLS_PATH: "Path to generate planning xls",
            notifications: "Notifications",
            verify_demand: "Verify demand",
            production_line_manual: "Linha de produção manual",
            production_line_smt: "Linha de produção SMT",
            next: "Next",
            previous: "Previous",
            select_demand: "Selecionar demanda",
            adjust_variables: "Adjust variables",
            define_period_and_shifts: "Set shifts and period",
            top_bottom_different_line: "Top / Bottom in different production line",
            planned_qty: "Qty. planned",
            head_count: "Head count",
            production_time: "Production time",
            ramp: "Ramp",
            input_a_value: "Input a value",
            greater_than_equal_0: "The field must be greater than or equal to 0",
            greater_than_equal_1: "The field must be greater than or equal to 1",
            less_than_equal_1000: "The field must be less than 1000",
            generate_demand: "Generate demands",
            line_setting: "Line setting",
            smt: "SMT",
            manual: "Manual",
            aligned: "Aligned",
            create: "Create",
            delete_simulation: "Clear future plannings",
            simulation_result: "Simulation result",
            sync: "Sync",
            delayed: "Delayed",
            total_simulations: "Total simulations",
            plannings_removed: "Plannings removed",
            optimization_progress: "Optimization progress",
            optimization_started: "Optimization started",
            optimization_finalized: "Optimization finalized",
            downtime_reason: "Downtime reason",
            downtime: "Downtime",
            scheduled: "Scheduled",
            unscheduled: "Unscheduled",
            downtime_ranking: "Stops ranking",
            reason_qty: "Reason quantity",
            downtime_summary_by_quantity_report: "Downtimes by time and quantity",
            downtime_summary_by_available_time_report: "Downtimes by available time",
            downtime_by_year: "Downtime by year",
            downtime_by_month: "Downtime by month",
            downtime_by_week: "Downtime by week",
            downtime_by_day: "Downtime by day",
            report_by_available_time: "Report by available time",
            time: "Time",
            report_type: "Report type",
            planned_quantity_required: "Planned quantity is required",
            capacity_required: "Capacity is required",
            import_production: "Import production",
            verify_planning: "Verify planning",
            produced_quantity: "Produced quantity",
            week: "Week",
            qty: "Quantity",
            product_name: "Product",
            segment_name: "Segment",
            realized_and_planned_by_year: "Realized vs planned by year",
            realized_and_planned_by_month: "Realized and planned bymonth",
            realized_and_planned_by_week: "Realized and planned by week",
            realized_and_planned_by_day: "Realized and planned by day",
            production_import_successful: "Production imported successfully",
            nothing_to_import: "Nothing to import",
            start_week: "Start week",
            end_week: "End week",
            must_be_bigger_equal_than_start_week: "Must be bigger equal than start week",
            select_by: "Select by",
            oee_month_goal: "OEE month goal",
            oee_year_goal: "OEE year goal",
            goal: "Goal",
            oee: "OEE",
            monthly: "Monthly",
            weekly: "Weekly",
            oee_one: "O.E.E.1",
            monthly_oee: "Monthly OEE1",
            year_oee: "Year OEE1",
            month_target: "Year target",
            year_target: "Month target",
            dashboard: "Dashboard",
            dashboard_oee: "Dashboard OEE",
            period: "Period",
            achieved: "Achieved",
            percentage: "Percentage",
            backlog: "Backlog",
            current_year_realized: "Current year realized",
            current_year_backlog: "Current year backlog",
            current_month_realized: "Current month realized",
            current_week_realized: "Current week realized",
            ft_test_buffer: "FTTest Buffer",
            wip_volume: "Wip Volume",
            import_line_schedule: "Import line schedule",
            serial_number: "Serial number",
            rev: "REV",
            bios_rev: "BIOS REV",
            model_name: "Model name",
            group_name: "Group name",
            next_station: "Next station",
            last_log: "Last log",
            working_time: "Working time",
            emp_no: "EMP NO",
            rota: "ROTA",
            total: "Total",
            line_schedule: "Line schedule",
            hour: "Hour",
            today: "Today",
            production_order_demand: "Demand's production orders",
            buffer_chart: "Buffer Chart",
            delivery_file: "Delivery file",
            columns: "Columns",
            released_forecast: "Released forecast",
            total_backlog: "Total Backlog",
            slc_on_hand: "SLC On-Hand (LT=0)",
            factory_on_hand: "Factory On-Hand",
            projected_inventory: "Projected Inventory",
            projected_dsi: "Projected DSI",
            local_balance: "Local Inventory",
            update_with_planned: "Update with planned",
            active_line_count: "Active lines",
            active_line_shift: "Active lines by shift",
            safety_stock_ok: "Safety stock ok",
            excess_stock: "Excess stock",
            stock_on_alert: "Stock on alert",
            critical_stock: "Critical stock",
            active_people_count: "Active people",
            active_people_line: "Active people per line",
            upph: "UPPH",
            uph: "UPH",
            upph_production_line: "UPPH production line",
            send_final_version_planning: "Send final version",
            successfully_send_planning: "Planning sent successfully",
            final_version_planning: "Final version planning",
            select_an_item_below: "Select an item below",
            more_options: "More options",
            invalid_emails: "Enter valid emails separated by semicolons.",
            last_updated: "Last updated",
            next_updated: "Next updated",
            automatic: "Automatic",
            manual_or_smt: "Manual or SMT",
            urgent_planning: "Urgent planning",
            initial_quantity: "Initial quantity",
            yearly_chart: "Yearly chart",
            monthly_chart: "Monthly chart",
            weekly_chart: "Weekly chart",
            daily_chart: "Daily chart",
            info_search_reports: "The values displayed correspond to the selected period",
        }
    }
};

export {messages};
