import React, {FC} from "react";
import "react-toastify/dist/ReactToastify.css";
import {CssBaseline, ThemeProvider} from "@mui/material";
import Theme from "./assets/theme/theme";
import {ToastContainer} from "react-toastify";
import {Routes} from "./app/routes/routes";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import "dayjs/locale/en";
import {translate} from "./app/translate/translate";
import dayjs from "dayjs";

const App: FC<any> = () => {
    const language = translate.language == "pt" ? "pt-br" : "en";
    dayjs().locale(language);
    return (
        <ThemeProvider theme={Theme.theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
                <CssBaseline/>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover/>
                <Routes/>
            </LocalizationProvider>
        </ThemeProvider>
    );

};

export default App;
