import * as React from "react";
import {useEffect, useState} from "react";
import {
    Box,
    Checkbox,
    Fab,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Paper,
    Typography
} from "@mui/material";
import {Search} from "@mui/icons-material";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import * as echarts from "echarts/core";
import {
    DataZoomComponent,
    GridComponent,
    LegendComponent,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent
} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";
import {BaseService} from "../../../services/base-service";
import {Downtime} from "../../../models/basic/downtime";
import {URLS} from "../../../services/app-urls";
import {translate} from "../../../translate/translate";
import {ErrorComponent} from "../../../components/error/error.component";
import {DowntimeReason} from "../../../models/basic/downtime-reason";
import Select from "@mui/material/Select";
import CategoricalReportComponent from "./categorical-report/categorical-report.component";
import {toast} from "react-toastify";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {useKeepFilters} from "../../../utils/form-utils";

echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

const DowntimeSummaryByQuantityReportComponent = () => {
    // Services
    const service = new BaseService<Downtime>(URLS.DOWNTIME);
    const downtime_reason_service = new BaseService<DowntimeReason>(URLS.DOWNTIME_REASON);

    // Hooks
    const [selectedDowntimeReasons, setSelectedDowntimeReasons] = useState<any[]>([]);
    const [showYearlyChart, setShowYearlyChart] = useState<boolean>(false);
    const [showMonthlyChart, setShowMonthlyChart] = useState<boolean>(false);
    const [showDailyChart, setShowDailyChart] = useState<boolean>(false);
    const [showWeeklyChart, setShowWeeklyChart] = useState<boolean>(false);
    const [reasons, setReasons] = useState<DowntimeReason[]>([]);
    const [reportData, setReportData] = useState<any>("");

    const {searchParams, setFilterParameter} = useKeepFilters();

    const start_date = dayjs(searchParams.get("start_date") || dayjs());
    const end_date = dayjs(searchParams.get("start_date") || dayjs());
    const report_type = searchParams.get("type") || "Q";

    // Form
    const schema = Yup.object().shape({
        downtime_reasons: Yup.array(),
        start_date: Yup.string().required(),
        final_date: Yup.string().required(),
        report_type: Yup.string().required(),
    });

    useEffect(() => {
        setFilterParameter("reasons_id", selectedDowntimeReasons.join(","));
    }, [selectedDowntimeReasons]);

    // Handle and get functions
    const handleDownTimeReasonChange = (reasonId: any) => {
        setShowYearlyChart(false);
        setShowMonthlyChart(false);
        setShowWeeklyChart(false);
        setShowDailyChart(false);
        setSelectedDowntimeReasons(reasonId);
    };

    const getReasons = () => {
        downtime_reason_service.clearParameters();
        downtime_reason_service.addParameter("ordering", "-id");
        downtime_reason_service.addParameter("active", true);
        downtime_reason_service.getAll()
            .then((response: any) => {
                setReasons(response.data);
            });
    };

    // Use effects
    useEffect(() => {
        getReasons();
    }, []);

    const handleInitialDateChange = (date: any) => {
        setShowYearlyChart(false);
        setShowMonthlyChart(false);
        setShowWeeklyChart(false);
        setShowDailyChart(false);
        setFilterParameter("start_date", date.format());
    };

    const handleFinalDateChange = (date: any) => {
        setShowYearlyChart(false);
        setShowMonthlyChart(false);
        setShowWeeklyChart(false);
        setShowDailyChart(false);
        setFilterParameter("end_date", date.format());
    };

    const getReportData = () => {
        setShowYearlyChart(false);
        setShowMonthlyChart(false);
        setShowWeeklyChart(false);
        setShowDailyChart(false);

        service.clearParameters();
        if (selectedDowntimeReasons) {
            service.addParameter("reasons_id", selectedDowntimeReasons);
        }
        service.addParameter("start_date", dayjs(start_date).format("YYYY-MM-DD"));
        service.addParameter("end_date", dayjs(end_date).format("YYYY-MM-DD"));
        service.addParameter("report_type", report_type);
        service.getFromListRoute("report_chart_downtime_summary_by_quantity")
            .then(response => {
                if (response.data) {
                    setReportData(response.data);
                    setShowYearlyChart(true);
                    setShowMonthlyChart(true);
                    setShowWeeklyChart(true);
                    setShowDailyChart(true);
                } else {
                    toast.warning(translate.t("empty_listing"));
                }
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const renderReasons = (selected: any) => {
        const selects: any[] = [];
        selected.map((reasonId: any) => {
            const description: any = reasons.find(reason => reasonId === reason.id)?.description;
            selects.push(description);
        });

        return selects.join(", ");
    };

    const handleReportTypeChange = (value: any) => {
        setShowYearlyChart(false);
        setShowMonthlyChart(false);
        setShowWeeklyChart(false);
        setShowDailyChart(false);
        setFilterParameter("report_type", value);
    };

    // Render return
    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("downtime_summary_by_quantity_report")}
                </Typography>
            </div>

            <Grid item xs>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            initialValues={{start_date: "", end_date: ""}}
                            validationSchema={schema}
                            validateOnChange={true}
                            validateOnMount={true}
                            enableReinitialize
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    getReportData();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                ({values, touched}) => {
                                    return (
                                        <Form autoComplete="off">
                                            <Grid alignItems="center" justifyContent={"flex-start"} container spacing={2} direction={"row"}>
                                                <Grid item xs>
                                                    <DatePicker
                                                        onChange={handleInitialDateChange}
                                                        maxDate={end_date}
                                                        slotProps={{
                                                            textField: {
                                                                required: true,
                                                                name: "start_date",
                                                                fullWidth: true,
                                                                label: translate.t("start_date"),
                                                                value: start_date,
                                                                error: !start_date || (end_date && (start_date > end_date)),
                                                                InputLabelProps: {
                                                                    shrink: true
                                                                },
                                                                InputProps: {
                                                                    startAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {!start_date ? (
                                                                                <div className={"required-field"}>
                                                                                    {translate.t("required_field")}
                                                                                </div>
                                                                            ) : null}
                                                                            {start_date && end_date && (start_date > end_date) ? (
                                                                                <div className={"error-field"}>
                                                                                    {translate.t("must_be_less_equal_than_final_date")}
                                                                                </div>
                                                                            ) : null}
                                                                        </InputAdornment>
                                                                    ),
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <DatePicker
                                                        onChange={handleFinalDateChange}
                                                        minDate={start_date}
                                                        slotProps={{
                                                            textField: {
                                                                required: true,
                                                                name: "end_date",
                                                                fullWidth: true,
                                                                label: translate.t("end_date"),
                                                                value: end_date,
                                                                error: !end_date || (start_date && (end_date < start_date)),
                                                                InputLabelProps: {
                                                                    shrink: true
                                                                },
                                                                InputProps: {
                                                                    startAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {!end_date ? (
                                                                                <div className={"required-field"}>
                                                                                    {translate.t("required_field")}
                                                                                </div>
                                                                            ) : null}
                                                                            {start_date && end_date && (end_date < start_date) ? (
                                                                                <div className={"error-field"}>
                                                                                    {translate.t("must_be_bigger_equal_than_start_date")}
                                                                                </div>
                                                                            ) : null}
                                                                        </InputAdornment>
                                                                    ),
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <FormControl fullWidth>
                                                        <InputLabel className={"formControl"} htmlFor="report_type">
                                                            {translate.t("report_type")} *
                                                        </InputLabel>
                                                        <Field
                                                            variant={"outlined"}
                                                            defaultValue="Q"
                                                            label={translate.t("report_type")}
                                                            name="report_type"
                                                            component={Select}
                                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => handleReportTypeChange(event.target.value as string)}
                                                        >
                                                            <MenuItem value="T">{translate.t("time")}</MenuItem>
                                                            <MenuItem value="Q">{translate.t("quantity")}</MenuItem>
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormControl fullWidth>
                                                        <InputLabel>{translate.t("downtime_reason")}</InputLabel>
                                                        <Select
                                                            name="downtimeReasons"
                                                            variant={"outlined"}
                                                            fullWidth
                                                            multiple
                                                            value={selectedDowntimeReasons}
                                                            onChange={(event: any) => handleDownTimeReasonChange(event.target.value)}
                                                            input={<OutlinedInput label={translate.t("downtime_reason")}/>}
                                                            renderValue={(selected) => renderReasons(selected)}
                                                            autoFocus={false}
                                                            required={false}>
                                                            {reasons.map((reason: any) => (
                                                                <MenuItem key={reason.id} value={reason.id}>
                                                                    <Checkbox checked={selectedDowntimeReasons.includes(reason.id)}/>
                                                                    <ListItemText primary={reason.description}/>
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <Fab
                                                        size="small"
                                                        color="secondary"
                                                        disabled={!start_date || !end_date || start_date > end_date}
                                                        onClick={getReportData}>
                                                        <Search/>
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                        </Formik>
                    </Box>
                </Paper>

                {showYearlyChart && (
                    <CategoricalReportComponent reportType={report_type} reportData={reportData.by_year} chartTitle={translate.t("downtime_by_year")}></CategoricalReportComponent>
                )}

                {showMonthlyChart && (
                    <CategoricalReportComponent reportType={report_type} reportData={reportData.by_month} chartTitle={translate.t("downtime_by_month")}></CategoricalReportComponent>
                )}

                {showWeeklyChart && (
                    <CategoricalReportComponent reportType={report_type} reportData={reportData.by_week} chartTitle={translate.t("downtime_by_week")}></CategoricalReportComponent>
                )}

                {showDailyChart && (
                    <CategoricalReportComponent reportType={report_type} reportData={reportData.by_day} chartTitle={translate.t("downtime_by_day")}></CategoricalReportComponent>
                )}

            </Grid>
        </section>
    );
};

export default DowntimeSummaryByQuantityReportComponent;
