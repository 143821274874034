import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Chip, Fab, Grid, Paper, Stack, Typography, Tooltip} from "@mui/material";
import EChartsReact, {EChartsEventInfo, useChart} from "echarts-for-react-fc";
import * as echarts from "echarts/core";
import {DataZoomComponent, GridComponent, LegendComponent, MarkLineComponent, MarkPointComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {ErrorComponent} from "../../../components/error/error.component";
import {translate} from "../../../translate/translate";
import Loading from "../../../components/loading/loading";
import {ActiveLineShift} from "../../../models/basic/active-line-shift";
import {Form, Formik} from "formik";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import {ProductionLine} from "../../../models/basic/production-line";
import {Shift} from "../../../models/basic/shift";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {Search, Error} from "@mui/icons-material";
import {generateOptions} from "../../../utils/chart-utils";

echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent, MarkPointComponent, MarkLineComponent]);

const DashboardUpphProductionLineComponent = () => {

    const service = new BaseService<ActiveLineShift>(URLS.ACTIVE_LINE_SHIFT);
    const [showChart, setShowChart] = useState<boolean>(false);
    const [showChartMonthly, setShowChartMonthly] = useState<boolean>(false);
    const [showChartWeekly, setShowChartWeekly] = useState<boolean>(false);
    const [showChartDaily, setShowChartDaily] = useState<boolean>(false);
    const [selectedYear, setSelectedYear] = useState<string>("");
    const [selectedMonth, setSelectedMonth] = useState<string>("");
    const [loading, setLoading] = React.useState(false);
    const [target, setTarget] = useState<any>();
    const productionLineService = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const [clearAutocompleteProductionLine, setClearAutocompleteProductionLine] = useState<boolean>(false);
    const [selectedProductionLines, setSelectedProductionLines] = useState<ProductionLine[]>([]);
    const shiftService = new BaseService<Shift>(URLS.SHIFT);
    const [clearAutocompleteShift, setClearAutocompleteShift] = useState<boolean>(false);
    const [selectedShifts, setSelectedShifts] = useState<Shift[]>([]);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [response, setResponse] = useState<any>();
    const {chartRef: chartRef, setChartOption: setChartOption, handleListenChartReady: handleChartReady} = useChart();
    const {chartRef: monthlyChartRef, setChartOption: setMonthlyChartOption, handleListenChartReady: handleMonthlyChartReady} = useChart();
    const {chartRef: weeklyChartRef, setChartOption: setWeeklyChartOption, handleListenChartReady: handleWeeklyChartReady} = useChart();
    const {chartRef: dailyChartRef, setChartOption: setDailyChartOption, handleListenChartReady: handleDailyChartReady} = useChart();

    useEffect(() => {
        resetCharts();
    }, [selectedProductionLines, selectedShifts, startDate, endDate]);

    const resetCharts = () => {
        setShowChart(false);
        setShowChartMonthly(false);
        setShowChartWeekly(false);
        setShowChartDaily(false);
    };

    const handleStartDateChange = (event: any) => {
        setStartDate(event);
        resetCharts();
    };

    const handleEndDateChange = (event: any) => {
        setEndDate(event);
        resetCharts();
    };

    const handleDeleteProductionLine = (productionLineToDelete: ProductionLine) => {
        setSelectedProductionLines((productionLines) => productionLines.filter((productionLine) => productionLine.id !== productionLineToDelete.id));
    };

    const handleDeleteShift = (shiftToDelete: Shift) => {
        setSelectedShifts((shifts) => shifts.filter((shift) => shift.id !== shiftToDelete.id));
    };

    const getData = () => {
        const productionLineIds = selectedProductionLines.map((productionLine) => productionLine.id);
        const shiftIds = selectedShifts.map((shift) => shift.id);
        setShowChart(false);
        setShowChartMonthly(false);
        setShowChartWeekly(false);
        setShowChartDaily(false);
        setLoading(true);
        service.clearParameters();
        service.addParameter("production_line_list", productionLineIds.join(","));
        service.addParameter("shift_list", shiftIds.join(","));
        service.addParameter("start_date", dayjs(startDate).format("YYYY-MM-DD"));
        service.addParameter("end_date", dayjs(endDate).format("YYYY-MM-DD"));
        service.getFromListRoute("get_upph_production_line_data")
            .then((response: any) => {
                setTarget(response.data.target);
                setLoading(false);
                setChartValues(response.data.data, response.data.target);
                setResponse(response.data.data);
            })
            .catch((error: any) => {
                setLoading(false);
                ErrorComponent(error);
            });
    };

    const setChartValues = (data: any[], target: number) => {
        const years = Object.keys(data);
        const annualTotals = years.map((year: any) => data[year].total || 0);
        const option = generateOptions(target, 2.5, annualTotals, years);
        setChartOption(option, true);
        setShowChart(true);
    };

    const handleYearClick = (params: any) => {
        if (params.componentType === "series") {
            setShowChartMonthly(true);
            const clickedYear = params.name;
            setSelectedYear(clickedYear);
            if (clickedYear) {
                const months = Object.keys(response[clickedYear]).filter(month => month !== "total");
                const monthlyTotals = months.map(month => response[clickedYear][month].total || 0);
                const option = generateOptions(target, 2.5, monthlyTotals, months);
                setMonthlyChartOption(option, true);
            }
        }
    };

    const handleMonthClick = (params: any) => {
        if (params.componentType === "series") {
            setShowChartWeekly(true);
            const clickedMonth = params.name;
            setSelectedMonth(clickedMonth);
            if (clickedMonth) {
                const weeks = Object.keys(response[selectedYear][clickedMonth]).filter(month => month !== "total");
                const weeklyTotals = weeks.map(week => response[selectedYear][clickedMonth][week].total || 0);
                const option = generateOptions(target, 2.5, weeklyTotals, weeks);
                setWeeklyChartOption(option, true);
            }
        }
    };

    const handleWeekClick = (params: any) => {
        if (params.componentType === "series") {
            setShowChartDaily(true);
            const clickedWeek = params.name;
            if (clickedWeek) {
                const days = Object.keys(response[selectedYear][selectedMonth][clickedWeek]).filter(month => month !== "total");
                const dailyTotals = days.map(day => response[selectedYear][selectedMonth][clickedWeek][day] || 0);
                const option = generateOptions(target, 2.5, dailyTotals, days);
                setDailyChartOption(option, true);
            }
        }
    };

    const onEventsYear: Record<string, EChartsEventInfo> = {
        click: {
            handler: handleYearClick,
        }
    };

    const onEventsMonth: Record<string, EChartsEventInfo> = {
        click: {
            handler: handleMonthClick,
        }
    };

    const onEventsWeek: Record<string, EChartsEventInfo> = {
        click: {
            handler: handleWeekClick,
        }
    };

    return (
        <Grid>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("upph_production_line")}
                </Typography>
            </div>
            <Loading open={loading}/>
            <Grid>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{}}
                            onSubmit={(_values: any, {setSubmitting}) => {
                                setTimeout(async () => {
                                    setClearAutocompleteProductionLine(true);
                                    setClearAutocompleteShift(true);
                                    getData();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {({errors}) => (
                                <Form autoComplete="off">
                                    <Grid alignItems="center" container spacing={2} direction={"row"}>
                                        <Grid item xs sm md lg xl>
                                            <DatePicker
                                                format="DD/MM/YYYY"
                                                maxDate={endDate}
                                                onChange={handleStartDateChange}
                                                slotProps={{
                                                    textField: {
                                                        name: "start_date",
                                                        fullWidth: true,
                                                        label: translate.t("start_date"),
                                                        value: startDate,
                                                        error: Boolean(errors.start_date),
                                                        placeholder: translate.t("start_date"),
                                                        InputLabelProps: {
                                                            shrink: true
                                                        },
                                                    },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs sm md lg xl>
                                            <DatePicker
                                                format="DD/MM/YYYY"
                                                minDate={startDate}
                                                onChange={handleEndDateChange}
                                                slotProps={{
                                                    textField: {
                                                        name: "end_date",
                                                        fullWidth: true,
                                                        label: translate.t("end_date"),
                                                        value: endDate,
                                                        error: Boolean(errors.end_date),
                                                        placeholder: translate.t("end_date"),
                                                        InputLabelProps: {
                                                            shrink: true
                                                        },
                                                    },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs sm md lg xl>
                                            <AutocompletePaginated
                                                display={["name"]}
                                                searchField="name"
                                                label="production_line"
                                                service={productionLineService}
                                                clear={clearAutocompleteProductionLine}
                                                onSelectElement={(productionLine: ProductionLine) => {
                                                    setClearAutocompleteProductionLine(false);
                                                    if (!selectedProductionLines.find((m) => m.id === productionLine.id)) {
                                                        setSelectedProductionLines([...selectedProductionLines, productionLine]);
                                                        setTimeout(() => setClearAutocompleteProductionLine(true), 200);
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs sm md lg xl>
                                            <AutocompletePaginated
                                                display={["name"]}
                                                searchField="name"
                                                label="shift"
                                                service={shiftService}
                                                clear={clearAutocompleteShift}
                                                onSelectElement={(shift: Shift) => {
                                                    setClearAutocompleteShift(false);
                                                    if (!selectedShifts.find((s) => s.id === shift.id)) {
                                                        setSelectedShifts([...selectedShifts, shift]);
                                                        setTimeout(() => setClearAutocompleteShift(true), 200);
                                                    }
                                                }}
                                            />
                                        </Grid>


                                        <Grid item xs={12} sm={12} md={1.6} lg={1.2} xl={0.9} container direction="row" alignItems="center" style={{gap: "8px", alignItems: "flex-end"}}>
                                            <Grid item>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    color="secondary"
                                                    disabled={!startDate || !endDate  || selectedShifts.length <= 0 || selectedProductionLines.length <= 0}>
                                                    <Search/>
                                                </Fab>
                                            </Grid>

                                            <Grid item>
                                                <Tooltip title={translate.t("info_search_reports")} arrow>
                                                    <Error color="secondary" fontSize="large"/>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>

                {selectedProductionLines.length > 0 && (
                    <Paper variant="outlined" style={{marginTop: "8px"}}>
                        <Box p={2} marginTop={2}>
                            <Stack direction="row" flexWrap="wrap" gap={1}>
                                {selectedProductionLines.map((productionLine) => (
                                    <Chip
                                        key={productionLine.id}
                                        label={productionLine.name}
                                        onDelete={() => handleDeleteProductionLine(productionLine)}
                                    />
                                ))}
                            </Stack>
                        </Box>
                    </Paper>
                )}

                {selectedShifts.length > 0 && (
                    <Paper variant="outlined" style={{marginTop: "8px"}}>
                        <Box p={2} marginTop={2}>
                            <Stack direction="row" flexWrap="wrap" gap={1}>
                                {selectedShifts.map((shift) => (
                                    <Chip
                                        key={shift.id}
                                        label={shift.name}
                                        onDelete={() => handleDeleteShift(shift)}
                                    />
                                ))}
                            </Stack>
                        </Box>
                    </Paper>
                )}
            </Grid>

            {showChart && (showChart && (selectedShifts.length > 0 || selectedProductionLines.length > 0)) && (
                <Grid container direction={"column"} gap={0} marginTop={1}>
                    <Grid item xs>
                        <Paper id={"chart"} sx={{padding: "16px"}}>
                            <Typography variant="h6" align="inherit" sx={{ marginBottom: "16px" }}>
                                {translate.t("yearly_chart")}
                            </Typography>
                            <EChartsReact
                                autoResize={true}
                                style={{width: "100%", height: 500}}
                                ref={chartRef}
                                echarts={echarts}
                                onChartReady={handleChartReady}
                                onEvents={onEventsYear}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            )}
            {showChartMonthly && (
                <Grid container direction={"column"} gap={0} marginTop={2}>
                    <Grid item xs>
                        <Paper id={"monthly-chart"} sx={{padding: "16px"}}>
                            <Typography variant="h6" align="inherit" sx={{ marginBottom: "16px" }}>
                                {translate.t("monthly_chart")}
                            </Typography>
                            <EChartsReact
                                autoResize={true}
                                style={{width: "100%", height: 500}}
                                ref={monthlyChartRef}
                                echarts={echarts}
                                onChartReady={handleMonthlyChartReady}
                                onEvents={onEventsMonth}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            )}
            {showChartWeekly && (
                <Grid container direction={"column"} gap={0} marginTop={2}>
                    <Grid item xs>
                        <Paper id={"weekly-chart"} sx={{padding: "16px"}}>
                            <Typography variant="h6" align="inherit" sx={{ marginBottom: "16px" }}>
                                {translate.t("weekly_chart")}
                            </Typography>
                            <EChartsReact
                                autoResize={true}
                                style={{width: "100%", height: 500}}
                                ref={weeklyChartRef}
                                echarts={echarts}
                                onChartReady={handleWeeklyChartReady}
                                onEvents={onEventsWeek}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            )}
            {showChartDaily && (
                <Grid container direction={"column"} gap={0} marginTop={2}>
                    <Grid item xs>
                        <Paper id={"daily-chart"} sx={{padding: "16px"}}>
                            <Typography variant="h6" align="inherit" sx={{ marginBottom: "16px" }}>
                                {translate.t("daily_chart")}
                            </Typography>
                            <EChartsReact
                                autoResize={true}
                                style={{width: "100%", height: 500}}
                                ref={dailyChartRef}
                                echarts={echarts}
                                onChartReady={handleDailyChartReady}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default DashboardUpphProductionLineComponent;
