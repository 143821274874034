import {BaseService} from "../../../../services/base-service";
import {Box, Button, FormControlLabel, Grid, InputAdornment, Paper, Switch, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ReasonReplanning} from "../../../../models/basic/reason-replanning";
import {ErrorComponent} from "../../../../components/error/error.component";
import {useKeepFilters} from "../../../../utils/form-utils";


const ReasonReplanningItemComponent = () => {
    const [object, setObject] = React.useState(new ReasonReplanning());
    const service = new BaseService<ReasonReplanning>(URLS.REASON_REPLANNING);
    const params = useParams();
    const url = "/registration/reason_replanning/";
    const [buttonValue, setButtonValue] = useState("");
    const navigate = useNavigate();
    const [isDefault, setIsDefault] = React.useState(false);

    const {searchParams} = useKeepFilters();
    const previousUrl = `${url}?${searchParams.toString()}`;

    const schema = Yup.object().shape({
        name: Yup.string().required(),
    });

    useEffect(() => {
        if (params["action"] != "create") {
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
                setIsDefault(response.data.default);
            });
        }
    }, []);

    const handleChangeDefault = (newValue: boolean) => {
        setIsDefault(newValue);
    };

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={previousUrl}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("reason_replanning")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={(values: ReasonReplanning, {resetForm}: FormikHelpers<ReasonReplanning>) => {
                                setTimeout(() => {
                                    const reason_replanning = new ReasonReplanning();
                                    Object.assign(reason_replanning, values);
                                    reason_replanning.default = isDefault;
                                    if (reason_replanning.id) {
                                        service.patch(reason_replanning, reason_replanning.id).then(() => {
                                            toast.success(translate.t("successfully_updated"));
                                            if (buttonValue === "save") {
                                                setObject(new ReasonReplanning());
                                                setIsDefault(false);
                                                resetForm();
                                            } else {
                                                navigate(url);
                                            }
                                        }).catch((error: any) => {
                                            ErrorComponent(error);
                                        });
                                    } else {
                                        service.save(reason_replanning)
                                            .then(() => {
                                                toast.success(translate.t("successfully_registered"));
                                                if (buttonValue === "save") {
                                                    setObject(new ReasonReplanning());
                                                    setIsDefault(false);
                                                    resetForm();
                                                } else {
                                                    navigate(url);
                                                }
                                            })
                                            .catch((error: any) => {
                                                ErrorComponent(error);
                                            });
                                    }
                                }, 500);
                            }}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"column"}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="name"
                                                label={translate.t("name")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={true}
                                                required={true}
                                                values={values}
                                                error={touched.name && Boolean(errors.name)}
                                                InputProps={{
                                                    maxLength: 54,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.name && Boolean(errors.name) && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        <Grid item container justifyContent={"space-between"}>
                                            <Grid item xs alignItems={"center"}>
                                                <FormControlLabel
                                                    control={
                                                        <Field
                                                            type="checkbox"
                                                            name="active"
                                                            component={Switch}
                                                            checked={isDefault}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                                handleChangeDefault(e.target.checked)}
                                                            color="secondary"
                                                        />
                                                    }
                                                    label={translate.t("default")}
                                                />
                                            </Grid>
                                            <Grid item container direction={"row"}>
                                                <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                    <Grid item>
                                                        <Button
                                                            className="button-options"
                                                            color="secondary"
                                                            component={NavLink}
                                                            to={previousUrl}
                                                            variant="outlined">
                                                            {translate.t("cancel")}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            className="button-options"
                                                            color="success"
                                                            variant="contained"
                                                            type="submit"
                                                            value="save_form"
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                setButtonValue(event.currentTarget.value);
                                                            }}
                                                            name="save_form"
                                                            disabled={!isValid || isValidating || !values.name}>
                                                            {translate.t("save_form")}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            className="button-options"
                                                            color="secondary"
                                                            variant="contained"
                                                            type="submit"
                                                            value="save"
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                setButtonValue(event.currentTarget.value);
                                                            }}
                                                            name="save"
                                                            disabled={!isValid || isValidating || !values.name}>
                                                            {translate.t("save_plus")}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </div>
        </section>
    );
};

export default ReasonReplanningItemComponent;
