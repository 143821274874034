import {useSearchParams} from "react-router-dom";

export const useKeepFilters = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const getQueryParamsObject = () => {
        return Object.fromEntries(searchParams.entries());
    };

    const setFilterParameter = (key: string, value: any, field = "id") => {
        if (typeof value === "object") {
            setSearchParams(params => {
                params.set(key, value ? String(value[field]) : "");
                return params;
            });
        } else {
            setSearchParams(params => {
                params.set(key, value);
                return params;
            });
        }
    };

    return {
        getQueryParamsObject,
        setFilterParameter,
        searchParams
    };
};
