import * as React from "react";
import {useEffect} from "react";
import {Box, Button, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, TextField, Typography} from "@mui/material";
import {NavLink, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Add, Search} from "@mui/icons-material";
import {toast} from "react-toastify";
import {Field, Form, Formik} from "formik";
import {BaseService} from "../../../services/base-service";
import {useAuth} from "../../../contexts/auth-context";
import {User} from "../../../models/account/user";
import {URLS} from "../../../services/app-urls";
import {PaginatedResult} from "../../../models/default/paginated-result";
import {translate} from "../../../translate/translate";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import TableActionComponent from "../../../components/table/table-action.component";
import Select from "@mui/material/Select";
import HistoryComponent from "../../../components/history/history.component";
import {ErrorComponent} from "../../../components/error/error.component";
import {useKeepFilters} from "../../../utils/form-utils";

const UserComponent = () => {
    const {user, logout} = useAuth();
    const navigate = useNavigate();
    const service = new BaseService<User>(URLS.USER);
    const [dataSource, setDataSource] = React.useState<User[] | []>([]);
    const [pagination, setPagination] = React.useState<PaginatedResult<User>>();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [idHistory, setIdHistory] = React.useState(0);
    const url = "/account/user/";

    const {searchParams, setFilterParameter} = useKeepFilters();
    const searchName = searchParams.get("name") || "";
    const searchStatus = searchParams.get("active") || "";

    const handleOpenDialogHistory = (id: number) => {
        setIdHistory(id);
        setOpenHistory(true);
    };

    const handleCloseDialogHistory = () => {
        setOpenHistory(false);
    };

    const search = (restartIndex = false) => {
        if (searchName || searchStatus) {
            service.clearParameters();
            service.addParameter("name", searchName);
            service.addParameter("is_active", searchStatus);
            service.addParameter("limit", rowsPerPage);
            service.addParameter("offset", (page * rowsPerPage));
            service.getAllPaginate().then(response => {
                setPagination(response.data);
            });
        } else {
            service.clearParameters();
            service.addParameter("limit", rowsPerPage);
            service.addParameter("offset", (page * rowsPerPage));
            service.getAllPaginate().then(response => {
                setPagination(response.data);
            });
        }
        restartIndex && setPage(0);
    };

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    useEffect(() => {
        search();
    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDoubleClick = (row: User) => {
        navigate(`${url}${row.id}?${searchParams.toString()}`);
    };

    const handleSwitch = (row: User, column: keyof User) => {
        (row[column] as boolean) = !row[column];
        const payload = {"is_active": row.is_active, "is_superuser": row.is_superuser};

        service.patch(payload, Number(row.id)).then(() => {
            toast.success(translate.t("successfully_updated"));
            service.clearParameters();
            search();

            if (row.username == user.username) {
                logout();
            }
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleDelete = (row: User) => {
        service.delete(Number(row.id)).then(() => {
            toast.success(translate.t("successfully_deleted"));
            service.clearParameters();
            search();
            if (row.username == user.username) {
                logout();
            }
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("user")}
                </Typography>
            </div>
            <div className="floating-button">
                <Button
                    component={NavLink}
                    to={url+"create/"}
                    startIcon={<Add/>}
                    variant="contained"
                    color="secondary">
                    {translate.t("new")}
                </Button>
            </div>

            <Grid item xs={12}>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{name: ""}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    search(true);
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                () => (
                                    <Form autoComplete="off">
                                        <Grid alignItems={"center"} container spacing={2} direction={"row"}>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    name="name"
                                                    label={translate.t("name")}
                                                    fullWidth
                                                    variant={"outlined"}
                                                    autoFocus={true}
                                                    value={searchName}
                                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                        setFilterParameter("name", event.target.value as string)}/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel className={"formControl"} htmlFor="status">
                                                            Status
                                                    </InputLabel>
                                                    <Field
                                                        variant={"outlined"}
                                                        defaultValue=""
                                                        name="status"
                                                        component={Select}
                                                        value={searchStatus}
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                            setFilterParameter("active", event.target.value as string)}>

                                                        <MenuItem value="">-</MenuItem>
                                                        <MenuItem value="true">{translate.t("is_active")}</MenuItem>
                                                        <MenuItem value="false">{translate.t("inactive")}</MenuItem>
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    color="secondary">
                                                    <Search/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            <Paper variant="outlined" sx={{marginTop: "16px"}}>
                <TablePaginationComponent
                    state={dataSource}
                    displayedColumnsShow={["#", "username", "name", "email", "is_superuser", "Status", "action"]}
                    displayedColumns={["id", "username", "name", "email", "is_superuser", "is_active", "action"]}
                    handleDelete={handleDelete}
                    url={url}
                    handleSwitch={handleSwitch}
                    handleDoubleClick={handleDoubleClick}
                    count={pagination?.count}
                    next={pagination?.next}
                    previous={pagination?.previous}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    actionsComponent={TableActionComponent}
                    handleOpenDialogHistory={handleOpenDialogHistory}
                    columnStyles={{1:"5%", 2:"20%", 3:"20%", 4:"25%", 5:"10%", 6:"8%",}}
                />
            </Paper>
            <HistoryComponent
                openHistory={openHistory}
                handleCloseDialogHistory={handleCloseDialogHistory}
                idHistory={idHistory}
                columns_history={[
                    "username", "name", "email", "is_superuser", "is_active", "is_staff", "is_default", "password"]}
                service={url}
            />
        </section>
    );

};

export default UserComponent;
