import {BaseService} from "../../../../services/base-service";
import {Box, Button, Grid, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ErrorComponent} from "../../../../components/error/error.component";
import ActivePeopleLineComponent from "./active-people-line/active-people-line.component";
import {ActivePeopleCount} from "../../../../models/basic/active-people-count";
import {useKeepFilters} from "../../../../utils/form-utils";


const ActivePeopleCountItemComponent = () => {
    const [object, setObject] = React.useState(new ActivePeopleCount());
    const service = new BaseService<ActivePeopleCount>(URLS.ACTIVE_PEOPLE_COUNT);
    const params = useParams();
    const url = "/registration/active_people_count/";
    const [buttonValue, setButtonValue] = useState("");
    const navigate = useNavigate();
    const {searchParams} = useKeepFilters();
    const previousUrl = `${url}?${searchParams.toString()}`;

    const schema = Yup.object().shape({
        year: Yup.number().required()
    });

    const newUrl = (value: any) => {
        const urlBase = `${url}${value}`;
        navigate(urlBase);
        window.history.replaceState(null, "", urlBase);
    };

    useEffect(() => {
        if (params["action"] != "create") {
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
            });
        }
    }, []);

    const handleSubmit = (values: ActivePeopleCount, {resetForm}: FormikHelpers<ActivePeopleCount>) => {
        const activeLineCount = new ActivePeopleCount();
        Object.assign(activeLineCount, values);
        service.save(activeLineCount)
            .then((response) => {
                toast.success(translate.t("successfully_registered"));
                if (buttonValue === "save") {
                    setObject(new ActivePeopleCount());
                    resetForm();
                } else {
                    setObject(response.data);
                    newUrl(response.data.id);
                }
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={previousUrl}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("active_people_count")}
                </Typography>
            </div>
            <div style={{display: "flex", flexDirection: "column", gap: "15px"}}>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={{
                                ...object,
                                year: object.year || new Date().getFullYear()
                            }}
                            onSubmit={handleSubmit}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"row"}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="year"
                                                type="number"
                                                label={translate.t("year")}
                                                variant={"outlined"}
                                                fullWidth
                                                required={true}
                                                values={values}
                                                error={touched.year && Boolean(errors.year)}
                                                disabled={object.id !== undefined}
                                                InputProps={{
                                                    maxLength: 4,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.year && Boolean(errors.year) && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        {
                                            !object.id &&
                                            <Grid item container direction={"row"}>
                                                <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                    <Grid item>
                                                        <Button
                                                            className="button-options"
                                                            color="secondary"
                                                            component={NavLink}
                                                            to={previousUrl}
                                                            variant="outlined">
                                                            {translate.t("cancel")}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            className="button-options"
                                                            color="success"
                                                            variant="contained"
                                                            type="submit"
                                                            value="save_form"
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                setButtonValue(event.currentTarget.value);
                                                            }}
                                                            name="save_form"
                                                            disabled={!isValid || isValidating || !values.year}>
                                                            {translate.t("save_form")}
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            className="button-options"
                                                            color="secondary"
                                                            variant="contained"
                                                            type="submit"
                                                            value="save"
                                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                                setButtonValue(event.currentTarget.value);
                                                            }}
                                                            name="save"
                                                            disabled={!isValid || isValidating || !values.year}>
                                                            {translate.t("save_plus")}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
                {
                    object &&
                    <ActivePeopleLineComponent
                        activePeopleCount={object}
                    />
                }
            </div>
        </section>
    );
};

export default ActivePeopleCountItemComponent;
