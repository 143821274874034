import {ProductionLine} from "../models/basic/production-line";

export class PlanningDemand {
    public initial_date: any;
    public production_line_top: ProductionLine | null;
    public production_line_bot: ProductionLine | null;
    public production_line_backend: ProductionLine | null;
    public quantity: string;
    public shift: string;
    public capacity: string;
    public shift_to_start: string;
    public production_ramp: string;
    public line_setup: string;
    
    constructor() {
        this.initial_date = "";
        this.production_line_top = new ProductionLine();
        this.production_line_bot = new ProductionLine();
        this.production_line_backend = new ProductionLine();
        this.quantity = "";
        this.shift = "";
        this.capacity = "";
        this.shift_to_start = "";
        this.production_ramp = "";
        this.line_setup = "";
    }
}
