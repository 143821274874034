import {BaseService} from "../../../../services/base-service";
import {Box, Button, Grid, InputAdornment, Paper, TextField, Typography,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MuiColorInput} from "mui-color-input";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Marker} from "../../../../models/basic/marker";
import {ErrorComponent} from "../../../../components/error/error.component";
import {useKeepFilters} from "../../../../utils/form-utils";


const MarkerItemComponent = () => {
    const [object, setObject] = React.useState(new Marker());
    const url = "/registration/marker/";
    const navigate = useNavigate();
    const params = useParams();
    const baseService = new BaseService<Marker>(URLS.MARKER);
    const [buttonValue, setButtonValue] = useState("");
    const [colorValue, setColorValue] = React.useState("#000000");
    const [colorMessage, setColorMessage] = React.useState("");
    const {searchParams} = useKeepFilters();
    const previousUrl = `${url}?${searchParams.toString()}`;

    const schema = Yup.object().shape({
        description: Yup.string().required()
    });

    useEffect(() => {
        if (params["action"] !== "create") {
            baseService.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
                setColorValue(response.data.color);
            });
        }
    }, []);

    const colorValidate = (newColorValue: string) => {
        const regex = /^#[0-9A-Fa-f]{6}$/;
        if (newColorValue.length === 0) {
            setColorMessage(translate.t("required_field"));
            return false;
        } else if (!regex.test(newColorValue)) {
            setColorMessage(translate.t("invalid_format"));
            return false;
        } else {
            setColorMessage("");
            return true;
        }
    };

    const handleChangeColor = (newColorValue: string) => {
        colorValidate(newColorValue);
        setColorValue(newColorValue);
    };

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={previousUrl}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("marker")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={(values: Marker, {resetForm}: FormikHelpers<Marker>) => {
                                setTimeout(() => {
                                    const marker = new Marker();
                                    Object.assign(marker, values);
                                    marker.color = colorValue;
                                    if (colorValidate(colorValue) && marker.id) {
                                        baseService.patch(marker, marker.id).then(() => {
                                            toast.success(translate.t("successfully_updated"));
                                            if (buttonValue === "save") {
                                                setObject(new Marker());
                                                setColorValue("#000000");
                                                resetForm();
                                            } else {
                                                navigate(url);
                                            }
                                        }).catch((error: any) => {
                                            ErrorComponent(error);
                                        });
                                    } else if (colorValidate(colorValue)) {
                                        baseService.save(marker)
                                            .then(() => {
                                                toast.success(translate.t("successfully_registered"));
                                                if (buttonValue === "save") {
                                                    setObject(new Marker());
                                                    setColorValue("#000000");
                                                    resetForm();
                                                } else {
                                                    navigate(url);
                                                }
                                            }).catch((error: any) => {
                                                ErrorComponent(error);
                                            });
                                    }
                                }, 500);
                            }}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"column"}>
                                        <Grid container spacing={2} item xs direction={"row"}>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    name="description"
                                                    label={translate.t("description")}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={true}
                                                    required={true}
                                                    values={values}
                                                    error={touched.description && Boolean(errors.description)}
                                                    InputProps={{
                                                        maxLength: 64,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {touched.description && Boolean(errors.description) && (
                                                                    <div className={"required-field"}>
                                                                        {translate.t("required_field")}
                                                                    </div>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                                <MuiColorInput
                                                    name="color"
                                                    label={translate.t("color")}
                                                    helperText={colorMessage}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={false}
                                                    format="hex"
                                                    isAlphaHidden
                                                    value={colorValue}
                                                    onChange={handleChangeColor}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container justifyContent={"space-between"}>
                                            <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        component={NavLink}
                                                        to={previousUrl}
                                                        variant="outlined">
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="success"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save_form"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save_form"
                                                        disabled={!isValid || isValidating}>
                                                        {translate.t("save_form")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save"
                                                        disabled={!isValid || isValidating}>
                                                        {translate.t("save_plus")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                            }
                        </Formik>
                    </Box>
                </Paper>
            </div>
        </section>
    );
};

export default MarkerItemComponent;
