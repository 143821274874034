import {BaseService} from "../../../../services/base-service";
import {Box, Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, Paper, Radio, RadioGroup, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import {Close} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ProductionLine} from "../../../../models/basic/production-line";
import {ProductionLineStationComponent} from "./production-line-station.component";
import {ErrorComponent} from "../../../../components/error/error.component";
import {useKeepFilters} from "../../../../utils/form-utils";


const ProductionLineItemComponent = () => {
    const [object, setObject] = React.useState(new ProductionLine());
    const service = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const params = useParams();
    const url = "/registration/production_line/";
    const [buttonValue, setButtonValue] = useState("");
    const navigate = useNavigate();
    const [type, setType] = React.useState<any>();
    const [displayContent, setDisplayContent] = useState(params["action"] != "create");
    const [open, setOpen] = React.useState(false);
    const {searchParams} = useKeepFilters();
    const previousUrl = `${url}?${searchParams.toString()}`;

    const schema = Yup.object().shape({
        name: Yup.string().required()
    });

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const updateStation = () => {
    };

    const handleCloseDialog = (confirm_change: boolean, type: string) => {
        if (confirm_change) {
            if (object.id) {
                object.force_update = true;
                object.type = type;

                service.patch(object, object.id)
                    .then(response => {
                        if (buttonValue === "save") {
                            setObject(new ProductionLine());
                            setType("");
                            setDisplayContent(false);
                        } else {
                            setObject(response.data);
                            newUrl(response.data.id);
                            setDisplayContent(true);
                            updateStation();
                        }
                    })
                    .catch((error: any) => {
                        ErrorComponent(error);
                    });
            }
        }
        setOpen(false);
    };

    const newUrl = (value: any) => {
        const urlBase = `${url}${value}`;
        navigate(urlBase);
        window.history.replaceState(null, "", urlBase);
    };

    useEffect(() => {
        if (params["action"] != "create") {
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
                setType(response.data.type);
            });
        }
    }, []);

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={previousUrl}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("production_line")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={(values: ProductionLine, {resetForm}: FormikHelpers<ProductionLine>) => {
                                setTimeout(() => {
                                    const production_line = new ProductionLine();
                                    Object.assign(production_line, values);
                                    production_line.type = type;
                                    if (production_line.id) {
                                        try {
                                            service.patch(production_line, production_line.id)
                                                .then((response) => {
                                                    toast.success(translate.t("successfully_updated"));
                                                    if (buttonValue === "save") {
                                                        setObject(new ProductionLine());
                                                        setType("");
                                                        resetForm();
                                                        setDisplayContent(false);
                                                    } else {
                                                        setObject(response.data);
                                                        newUrl(response.data.id);
                                                        setDisplayContent(true);
                                                        navigate(url);
                                                    }
                                                })
                                                .catch((error) => {
                                                    if (error.response && error.response.status === 400) {
                                                        handleOpenDialog();
                                                    }
                                                });
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    } else {
                                        try {
                                            service.save(production_line)
                                                .then(response => {
                                                    toast.success(translate.t("successfully_registered"));
                                                    if (buttonValue === "save") {
                                                        setObject(new ProductionLine());
                                                        resetForm();
                                                        setType("");
                                                        setDisplayContent(false);
                                                    } else {
                                                        setObject(response.data);
                                                        newUrl(response.data.id);
                                                        setDisplayContent(true);
                                                    }
                                                })
                                                .catch((error) => {
                                                    if (error.response && error.response.status === 400) {
                                                        const message_error =
                                                            error.response.data.name || "error_required_field";
                                                        toast.error(translate.t(message_error));
                                                    }
                                                });
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    }
                                }, 500);
                            }}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"column"}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="name"
                                                label={translate.t("name")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={true}
                                                required={true}
                                                values={values}
                                                error={touched.name && Boolean(errors.name)}
                                                InputProps={{
                                                    maxLength: 104,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.name && Boolean(errors.name) && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        <Grid
                                            sx={{paddingLeft: "15px", paddingTop: "10px"}}
                                            container alignItems="center">
                                            <FormControl component="fieldset">
                                                <Grid container alignItems="center">
                                                    <FormLabel
                                                        required={true}
                                                        sx={{paddingRight: "10px"}}
                                                        component="legend">
                                                        {translate.t("type")}:</FormLabel>
                                                    <RadioGroup
                                                        row name="type"
                                                        onChange={(event) =>
                                                            setType(event.target.value)}>
                                                        <FormControlLabel
                                                            value="S" control={<Radio/>}
                                                            checked={type === "S"} label="SMT"/>
                                                        <FormControlLabel
                                                            value="M" control={<Radio/>}
                                                            checked={type === "M"} label="Manual"/>
                                                    </RadioGroup>
                                                </Grid>
                                            </FormControl>
                                        </Grid>
                                        <Grid item container direction={"row"}>
                                            <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        component={NavLink}
                                                        to={previousUrl}
                                                        variant="outlined">
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="success"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save_form"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save_form"
                                                        disabled={!isValid || isValidating || !values.name || !type}>
                                                        {translate.t("save_form")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save"
                                                        disabled={!isValid || isValidating || !values.name || !type}>
                                                        {translate.t("save_plus")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
                {displayContent ? (
                    <Grid sx={{gap: "8px", marginTop: "16px"}}>
                        <ProductionLineStationComponent
                            productionLine={object}
                            updateStations={updateStation}
                            typeProcess={object.type}/>
                    </Grid>
                ) : null}

                <Dialog open={open} onClose={handleCloseDialog}>
                    <Grid container justifyContent="space-between" sx={{backgroundColor: "primary.main"}}>
                        <Grid item m={1}>
                            <Typography color={"#ffffff"}>
                                {translate.t("confirm_type_process")}
                            </Typography>
                        </Grid>
                        <Grid item m={1} sx={{cursor: "pointer", color: "#ffffff"}}>
                            <Close onClick={() => {
                                handleCloseDialog(false, "");
                            }}/>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <Grid container flexDirection="column" gap={3}>
                            <Grid item>
                                <Typography>
                                    {translate.t("confirm_type_process_text")}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container justifyContent="flex-end" gap={2}>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        handleCloseDialog(false, "");
                                    }}>
                                    {translate.t("cancel")}</Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        handleCloseDialog(true, type);
                                    }}>
                                    {translate.t("proceed")}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </div>
        </section>
    );
};

export default ProductionLineItemComponent;
