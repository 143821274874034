export const roundToNearestHalfOrWhole = (value: number) => {
    const floorValue = Math.floor(value);
    const decimalPart = value - floorValue;
    return decimalPart < 0.5 ? floorValue + 0.5 : floorValue + 1;
};

const initOption: any = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow"
        }
    },
    toolbox: {
        feature: {
            saveAsImage: {show: true}
        }
    },
    legend: {
        data: []
    },
    xAxis: {
        type: "category",
        data: [],
        axisLabel: {
            rotate: 45,
            interval: 0
        }
    },
    yAxis: {
        type: "value",
        name: "",
        axisLabel: {
            formatter: (value: number) => value.toFixed(2)
        }
    },
    series: []
};

export const generateOptions = (target: number, targetDefault: number, totals: any, xAxisData: any, name?: string) => {
    const option = JSON.parse(JSON.stringify(initOption));

    option.yAxis = {
        type: "value",
        min: 0,
        max: roundToNearestHalfOrWhole(Math.max(target ?? targetDefault, Math.max(...totals)))
    };

    option.xAxis.data = xAxisData;

    option.series = [
        {
            name: name ?? "UPPH",
            type: "bar",
            data: totals,
            itemStyle: {
                color: "#7393EE",
            },
            markLine: {
                data: [
                    {
                        yAxis: target
                    }
                ],
                symbol: ["none", "none"],
                lineStyle: {type: "solid", width: 4, color: "#e83f3f"}
            }
        }
    ];

    option.tooltip = {
        trigger: "axis",
        axisPointer: {
            type: "shadow"
        }
    };

    option.legend = {
        show: true,
    };

    return option;
};
